import React, { useContext } from "react";
import { Redirect } from "react-router";
import Cookies from "js-cookie";
import { aiApiConfigs, aiDomainConfigs } from "../config/Config";

const API_URL = aiApiConfigs.API_URL;

// const ALTERNATIVE_API_URL = aiApiConfigs.ALTERNATIVE_API_URL;

// export const droplet2Login = async () => {
//   var myHeaders = new Headers();

//   var formdata = new FormData();
//   formdata.append("email", "kb@gmail.com");
//   formdata.append("password", "kb123");

//   var requestOptions = {
//     method: "POST",
//     headers: myHeaders,
//     body: formdata,
//     redirect: "follow",
//   };

//   try {
//     const droplet2Login = await fetch(
//       ALTERNATIVE_API_URL + "/auth/dj-rest-auth/login/",
//       requestOptions
//     );

//     try {
//       return await droplet2Login.json();
//     } catch (err) {
//       return(err)
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };

export const getTokensFromCookies = () => {
  const userState =
    Cookies.get(aiDomainConfigs.USER_STATE) != undefined
      ? JSON.parse(Cookies.get(aiDomainConfigs.USER_STATE))
      : null;
  return userState;
};

export const getPlanDetailsFromCookies = () => {
  const planState =
    Cookies.get(aiDomainConfigs.PLAN_STATE) != undefined
      ? JSON.parse(Cookies.get(aiDomainConfigs.PLAN_STATE))
      : null;
  return planState;
};

export const setTokensOnCookies = (access) => {
  let userData = getTokensFromCookies();
  if (access) {
    userData = { ...userData, token: access };
    Cookies.set(
      aiDomainConfigs.USER_STATE,
      JSON.stringify(userData),
      { domain: aiDomainConfigs.COOKIE_DOMAIN },
      { expires: 15 }
    );
    window.location.reload();
  } else return;
};

export const setRequestSource = () => {
  Cookies.set("redirection_domain", "static", {
    domain: aiDomainConfigs.COOKIE_DOMAIN,
  });
};

export const activateUserAccount = async (email) => {
  var formdata = new FormData();
  formdata.append("email", email);

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      API_URL + "/auth/activate-account/",
      requestOptions
    );

    let data = await response.json();
    let status = await response.status;

    return { data: data, status: status };
  } catch (err) {
    return err;
  }
};

export const getProjectsFromTransEditor = async (access_token) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer  ${access_token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const projectSetupResponse = await fetch(
      API_URL + "/workspace/project/quick/setup/",
      requestOptions
    );

    try {
      return await projectSetupResponse.json();
    } catch (err) {
      return err;
    }
  } catch (error) {
    return error;
  }
};

export const getProjectFilesAndJobsFromTransEditor = async (
  access_token,
  project_id
) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer  ${access_token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const projectFilesAndJobsResponse = await fetch(
      API_URL + `/workspace/files_jobs/${project_id}`,
      requestOptions
    );

    try {
      return await projectFilesAndJobsResponse.json();
    } catch (err) {
      return err;
    }
  } catch (error) {
    return error;
  }
};

export const getFilesDocumentURLFromTransEditor = async (
  access_token,
  project_id
) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer  ${access_token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const projectVendorDashboardResponse = await fetch(
      API_URL + `/workspace/vendor/dashboard/${project_id}`,
      requestOptions
    );

    try {
      return await projectVendorDashboardResponse.json();
    } catch (err) {
      return err;
    }
  } catch (error) {
    return error;
  }
};

export const getFilesDocumentIdFromTranseditor = async (
  access_token,
  document_url
) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer  ${access_token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const projectVendorDashboardResponse = await fetch(
      API_URL + document_url,
      requestOptions
    );

    try {
      return await projectVendorDashboardResponse.json();
    } catch (err) {
      return err;
    }
  } catch (error) {
    return error;
  }
};

export const getTranslationCredits = async (accessToken) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer  ${accessToken}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const translation_credits = await fetch(
      API_URL + "/workspace/dashboard_credit_status",
      requestOptions
    );

    try {
      return await translation_credits.json();
    } catch (err) {
      return err;
    }
  } catch (e) {
    return e;
  }
};

export const protect = async (accessToken, refreshToken) => {
  let callBack = null;
  accessToken = await hasAccess(accessToken, refreshToken);
  if (accessToken === undefined) {
    <Redirect to="/logout" />;
  } else {
    try {
      callBack = await requestLogin(accessToken, refreshToken);

      if (callBack === true) {
        // console.log("Refreshed");
        return true;
      } else {
        <Redirect to="/logout" />;
      }
    } catch (error) {
      return error;
    }
  }
};

const requestLogin = async (accessToken, refreshToken) => {
  let response = null;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    return new Promise(async (resolve, reject) => {
      try {
        response = await fetch(
          API_URL + "/auth/dj-rest-auth/user/",
          requestOptions
        );

        const data = await response?.json();
        const status = await response?.status;

        if (status === 401) {
          // console.log("Refreshing");
          if (data.code === "token_not_valid") {
            const accessToken = await refresh(refreshToken);
            return await requestLogin(accessToken, refreshToken);
          }
          resolve(false);
        } else {
          // protected route can be accessed
          resolve(true);
        }
      } catch (e) {
        return e;
      }
    });
  } catch (e) {
    return e;
  }
};

const refresh = (refreshToken) => {
  // const user = useContext(UserContext);
  // console.log("Refreshing.....");
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      refresh: refreshToken,
    }),
  };

  return new Promise(async (resolve, reject) => {
    try {
      const login_response = await fetch(
        API_URL + "/auth/dj-rest-auth/token/refresh/",
        requestOptions
      );
      const data = await login_response.json();
      const status = await login_response.status;

      if (!data.access) {
        Cookies.remove(aiDomainConfigs.USER_STATE);
        window.location.href = "/logout";
        resolve(false);
      } else {
        const { access } = data;
        setTokensOnCookies(access);

        // Cookies.set("Ailaysa-UAT", access);
        // let userState = {
        //   name: user?.permission_tokens?.name,
        //   email: user?.permission_tokens?.email,
        //   token: data?.access_token,
        //   refresh_token: user?.permission_tokens?.refresh_token,
        // };

        // Cookies.set(
        //   aiDomainConfigs.USER_STATE,
        //   userState,
        //   { domain: aiDomainConfigs.COOKIE_DOMAIN },
        //   { expires: 15 }
        // );

        // Cookies.set("Ailaysa-UAT", access);
        resolve(access);
      }
    } catch (error) {
      return error;
    }
  });
};

export const hasAccess = async (accessToken, refreshToken) => {
  if (!refreshToken) return undefined;
  if (accessToken === undefined) {
    // generate new AT
    accessToken = await refresh(refreshToken);
  }
  return accessToken;
};

export const getUserInfo = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/auth/dj-rest-auth/user/",
      requestOptions
    );
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getUserType = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await fetch(API_URL + "/auth/user-attr/", requestOptions);
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getProfileImages = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/auth/profile-images/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getLanguages = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(API_URL + "/app/language/", requestOptions);

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getCountries = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(API_URL + "/app/countries/", requestOptions);

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getIndianStates = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    // headers: {
    //   "Content-Type": "application/json",
    //   Authorization: `Bearer ${accessToken}`,
    // },
  };
  try {
    const response = await fetch(
      API_URL + "/app/indian-states/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getContentTypes = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(API_URL + "/app/contenttype/", requestOptions);

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getIndustries = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/app/subjectfield/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getSupportedFileFormats = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/app/supportfiles/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getTransEditorSupportedFileFormats = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/workspace_okapi/file_extensions/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getServiceTypes = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/app/servicetypes/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getMTEngines = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/app/mtpe-engines/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getTimeZones = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(API_URL + "/app/timezones/", requestOptions);

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getCurrencies = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(API_URL + "/app/currencies/", requestOptions);

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getJobPositions = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/app/job-positions/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getSupportRequestTypes = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/app/general-support-topics/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getBillingUnits = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(API_URL + "/app/billunits/", requestOptions);

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getProfileInfo = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/auth/aiuser-profile/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getBillingAddress = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/auth/billing-address/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getTaxIdentities = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/app/stripe-tax-ids/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getUserTaxIdentity = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(API_URL + "/auth/tax-info/", requestOptions);

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getPersonalInformation = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/auth/user-personal-info",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getOrganizationInformation = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/auth/user-official-info",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getVendorPrimaryInformation = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/vendor/vendor_info/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getVendorLegalCategories = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/vendor/vendor_legal_categories/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getVendorExpertiseInformation = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/vendor/vendorexpertiseinfo/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getCATAndOtherSoftware = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/vendor/cat_softwares/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getVendorMemberships = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/vendor/membership_list/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getVendorBankInformation = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/vendor/vendor_bank_info/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getVendorServicesInformation = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/vendor/vendorserviceinfo/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getVendorServiceUnitTypes = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/app/servicetypeunits/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const cloneServiceAndRatesData = async (accessToken, uid) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + `/vendor/clone/${uid}`,
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getUserProfile = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/auth/userprofile/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getRequestTypesForSupport = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/app/support_types/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getSubscriptionPlans = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    // headers: {
    //   Authorization: `Bearer ${accessToken}`,
    // },
  };
  try {
    const response = await fetch(
      API_URL + "/app/get_price_details/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getAddonsPlan = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/app/addon_details/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getSubscriptionPayments = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/auth/get_payment_details_subscription/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const getAddonPayments = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/auth/get_payment_details_addon/",
      requestOptions
    );

    return await response.json();
  } catch (err) {
    return err;
  }
};

export const checkSubscription = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/auth/check-subscription/",
      requestOptions
    );

    let data = await response.json();
    let status = await response.status;

    return { data: data, status: status };
  } catch (err) {
    return err;
  }
};

export const signupOnStripe = async (accessToken) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await fetch(
      API_URL + "/auth/usersubscribe/",
      requestOptions
    );

    let data = await response.json();
    let status = await response.status;

    return { data: data, status: status };
  } catch (err) {
    return err;
  }
};

export const getCheckoutLinkForPlanUpgrade = async (access_token, price_id) => {
  let myHeaders = new Headers();
  let formdata = new FormData();

  myHeaders.append("Authorization", `Bearer ${access_token}`);

  formdata.append("price", price_id);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      API_URL + "/auth/buy-subscription/",
      requestOptions
    );

    let data = await response.json();
    let status = await response.status;

    return { data: data, status: status };
  } catch (err) {
    return err;
  }
};

export const openStripeCustomerPortal = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(
      API_URL + "/auth/stripe-customer-portal/",
      requestOptions
    );

    let data = await response.json();
    let status = await response.status;

    return { data: data, status: status };
  } catch (err) {
    return err;
  }
};

export const becomeAnEditor = async (data) => {
  var myHeaders = new Headers();
  var formdata = new FormData();

  formdata.append("name", data.name);
  formdata.append("email", data.email);
  formdata.append("cv_file", data.file, data.file.path);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      API_URL + "/auth/vendor-onboarding/",
      requestOptions
    );

    let data = await response.json();
    let status = await response.status;

    return { data: data, status: status };
  } catch (err) {
    return err;
  }
};

export const submitCareerApplicationForm = async (data) => {
  var myHeaders = new Headers();
  var formdata = new FormData();

  formdata.append("name", data.name);
  formdata.append("email", data.email);
  formdata.append("phonenumber", data.phone);
  formdata.append("job_position", data.position);
  formdata.append("message", data.message);

  formdata.append("cv_file", data.file, data.file.path);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      API_URL + "/auth/carrier-support/",
      requestOptions
    );

    let data = await response.json();
    let status = await response.status;

    return { data: data, status: status };
  } catch (err) {
    return err;
  }
};

export const submitSupportForm = async (data) => {
  var myHeaders = new Headers();
  var formdata = new FormData();

  formdata.append("name", data.name);
  formdata.append("email", data.email);
  formdata.append("phonenumber", data.phone);
  formdata.append("topic", data.position);
  formdata.append("message", data.message);

  data.file ? formdata.append("support_file", data.file, data.file.path) : null;

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      API_URL + "/auth/general-support/",
      requestOptions
    );

    let data = await response.json();
    let status = await response.status;

    return { data: data, status: status };
  } catch (err) {
    return err;
  }
};

export const submitContactUsForm = async (data) => {
  var myHeaders = new Headers();
  var formdata = new FormData();

  formdata.append("name", data.name);
  formdata.append("business_email", data.email);
  formdata.append("description", data.message);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(API_URL + "/auth/contact_us/", requestOptions);

    let data = await response.json();
    let status = await response.status;

    return { data: data, status: status };
  } catch (err) {
    return err;
  }
};

export const saveBillingAddress = async (accessToken, data) => {
  const {
    fullName,
    addressLine1,
    addressLine2,
    city,
    userState,
    userStateText,
    country,
    postalCode,
    taxNumber,
  } = data;
  let myHeaders = new Headers();
  let formdata_1 = new FormData();
  let formdata_2 = new FormData();

  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  formdata_1.append("name", fullName);
  formdata_1.append("line1", addressLine1);
  formdata_1.append("line2", addressLine2);
  formdata_1.append(
    "state",
    userState?.length > 0
      ? userState
      : userStateText?.length > 0
      ? userStateText
      : null
  );
  formdata_1.append("zipcode", postalCode);
  formdata_1.append("country", country);
  formdata_1.append("city", city);

  if (taxNumber?.length > 0) {
    formdata_2.append("stripe_tax_id", 25);
    taxNumber.length > 0 ? formdata_2.append("tax_id", taxNumber) : null;
  }

  let requestBillingAddressOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata_1,
    redirect: "follow",
  };

  let taxInformationRequestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata_2,
    redirect: "follow",
  };

  try {
    if (taxNumber?.length > 0) {
      const tax_info_response = await saveTaxInformation(
        taxInformationRequestOptions
      );
      if (tax_info_response?.status === 200) {
        // console.log("saved_with_tax_info");
      } else if (tax_info_response?.status === 422) {
        toast.error(t("invalid_tax_id"), {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      } else {
        toast.error(t("something_went_wrong"), {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
    }

    try {
      const response = await fetch(
        API_URL + `/auth/billing-address/`,
        requestBillingAddressOptions
      );

      let data = await response.json();
      let status = await response.status;

      return { data: data, status: status };
    } catch (error) {
      console.log(error);
    }
  } catch (err) {
    return err;
  }
};

const saveTaxInformation = async (requestOptions) => {
  try {
    const new_response = await fetch(
      API_URL + `/auth/tax-info/`,
      requestOptions
    );

    if (new_response?.status === 200) {
      return new_response;
    } else {
      return 0;
    }
  } catch (error) {
    return error;
  }
};

export const approvedEditorverification = async (access_token, email) => {
  // var myHeaders = new Headers();
  // myHeaders.append("Authorization", `Bearer  ${access_token}`);

  let formdata = new FormData();
  formdata.append("email", email);

  var requestOptions = {
    method: "POST",
    // headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const editorApproval = await fetch(
      API_URL + `/auth/vendor_form_filling_status/`,
      requestOptions
    );

    if (editorApproval?.status === 204) {
      return { status: editorApproval?.status };
    } else {
      try {
        return {
          data: await editorApproval.json(),
          status: editorApproval?.status,
        };
      } catch (err) {
        return err;
      }
    }
  } catch (error) {
    return error;
  }
};
