module.exports = {
  aiApiConfigs: {
    SERVER_IP: process.env.REACT_APP_SERVER_IP,
    API_URL: process.env.REACT_APP_API_URL,
    LOCAL_API_URL: process.env.REACT_APP_LOCAL_API_URL,
    LOCAL_NETWORK_URL: process.env.REACT_APP_LOCAL_NETWORK_URL,
    NETWORK_STATIC_URL: process.env.REACT_APP_NETWORK_STATIC_URL,
    // SKIP_PREFLIGHT_CHECK=true to be added in .env file if required
  },
  aiDomainConfigs: {
    AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN,
    BASE_DOMAIN: process.env.REACT_APP_BASE_DOMAIN,
    HOST_DOMAIN: process.env.REACT_APP_HOST_DOMAIN,
    TRANSEDITOR_DOMAIN: process.env.REACT_APP_TRANSEDITOR_DOMAIN,
    KNOWLEDGEBASE_DOMAIN: process.env.REACT_APP_KNOWLEDGBASE_DOMAIN,
    MARKETPLACE_DOMAIN: process.env.REACT_APP_MARKETPLACE_DOMAIN,
    LOCALHOST_URL: process.env.REACT_APP_LOCALHOST_URL,
    COOKIE_DOMAIN: process.env.REACT_APP_AILAYSA_DOMAIN,
    COOKIE_DOMAIN_NEW: process.env.REACT_APP_COOKIE_DOMAIN,
    USER_STATE: "userStateStaging",
    PLAN_STATE: "planStateStaging",
    CDN_DOMAIN: process.env.REACT_APP_CDN
  },
};
