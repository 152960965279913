import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../App";
import { Link, useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Trans, useTranslation } from "react-i18next";
import Cookies from "js-cookie";

import Config, { aiApiConfigs, aiDomainConfigs } from "../config/Config";

import {
  protect,
  getProfileImages,
  getTokensFromCookies,
  activateUserAccount,
} from "../helper/ProtectedCallHelper";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CloseButton from "react-bootstrap/CloseButton";
import Rodal from "rodal";
import "rodal/lib/rodal.css";

toast.configure();

const AccountActivated = (props) => {
  const API_URL = aiApiConfigs.API_URL;
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const history = useHistory();

  let accessToken = user?.permission_tokens?.token;
  let refreshToken = user?.permission_tokens?.refresh_token;

  const [avatarTitle, setAvatarTitle] = useState("");
  const [logoTitle, setLogoTitle] = useState("");
  const [avatar, setAvatar] = useState([]);
  const [logo, setLogo] = useState([]);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [APICallMethod, setAPICallMethod] = useState("");
  const [isUploaded, setUploaded] = useState(false);
  const [isRemoved, setRemoved] = useState(false);

  useEffect(() => {
    setDisableSaveButton(false);
  }, [avatar, logo]);

  useEffect(() => {
    user.profileImages?.id
      ? setAPICallMethod("PATCH")
      : setAPICallMethod("POST");
  }, [user?.profileImages]);

  const modaloption = {
    closeMaskOnClick: false,
    onClose: props.onHide,
  };

  const ModalConfirmButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#0078D4",
      boxShadow: "none",
      borderRadius: "2px",
      textTransform: "none",
      padding: "11px 22px",
      "&:hover": {
        backgroundColor: "#0265b1",
        boxShadow: "none",
      },
    },
  }))(Button);

  const reloadCurrentPage = () => {
    if (window.location.pathname === "/")
      window.location.href = window.location.href + "home?reactivated=true";
    else window.location.href = window.location.href + "?reactivated=true";
  };

  return (
    <Rodal
      {...props}
      {...modaloption}
      visible={props.show}
      showCloseButton={false}
      className="account-deactivation-and-deletion"
    >
      <div className="new-modal-close-btn" onClick={props.onHide}>
        <img
          src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/icons/close_black.svg"}
          alt="close_black"
        />
      </div>
      <div className="active-deactive-modal-body">
        <div className="active-deactive-content-box">
          {/* <p>{t("deactivate_account_message_1")}</p> */}
          {/* <p>{t("deactivate_account_message_2")}</p> */}
          <img
            src={
              Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/auth/congrats-tick.svg"
            }
            alt="account-active"
          />
          {/* <p>
            <Trans>account_activated_message</Trans>
          </p> */}
          <h3>{t("active_account_header")}</h3>
          <p>{t("active_account_description")}</p>
        </div>
        <div className="active-deactive-button-box">
          <>
            <ModalConfirmButton
              disabled={disableSaveButton}
              onClick={() => reloadCurrentPage()}
            >
              <span className="active-deactive-modal-btn-white-txt">
                <Trans>{t("get_started_again")}</Trans>
              </span>
            </ModalConfirmButton>
            {/* <UploadFileCloseButton className="mt-2" onClick={props.onHide}>
              <span className="modal-close-btn">{t("cancel")}</span>
            </UploadFileCloseButton> */}
          </>
        </div>
      </div>
    </Rodal>
  );
};

export default AccountActivated;
