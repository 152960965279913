import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../App";
import { Link, useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Trans, useTranslation } from "react-i18next";
import Cookies from "js-cookie";

import Config, { aiApiConfigs, aiDomainConfigs } from "../config/Config";

import {
  getTokensFromCookies,
  activateUserAccount,
} from "../helper/ProtectedCallHelper";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CloseButton from "react-bootstrap/CloseButton";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import AccountActivated from "./ActivatedModal";

toast.configure();

const ActivateAccount = (props) => {
  const API_URL = aiApiConfigs.API_URL;
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const history = useHistory();

  let accessToken = user?.permission_tokens?.token;
  let refreshToken = user?.permission_tokens?.refresh_token;

  const [avatarTitle, setAvatarTitle] = useState("");
  const [logoTitle, setLogoTitle] = useState("");
  const [avatar, setAvatar] = useState([]);
  const [logo, setLogo] = useState([]);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [APICallMethod, setAPICallMethod] = useState("");
  const [isUploaded, setUploaded] = useState(false);
  const [isRemoved, setRemoved] = useState(false);

  const [title, setTitle] = useState("");
  const [activatedModalShow, setActivatedModalShow] = useState(false);

  useEffect(() => {
    setDisableSaveButton(false);
  }, [avatar, logo]);

  useEffect(() => {
    user.profileImages?.id
      ? setAPICallMethod("PATCH")
      : setAPICallMethod("POST");
  }, [user?.profileImages]);

  const modaloption = {
    closeMaskOnClick: false,
    onClose: props.onHide,
  };

  const showAccountActivationRequiredModal = () => {
    setTimeout(() => {
      setTitle(t("activated"));
      setActivatedModalShow(true);
    }, 200);
  };

  const handleAccountActivation = async () => {
    try {
      const user_activation = await activateUserAccount(user?.userInfo?.email);
      const data = await user_activation?.data;
      const status = await user_activation?.status;

      if (status === 200) {
        let userData = getTokensFromCookies(accessToken);
        if (userData) {
          userData = {
            ...userData,
            user_status: true,
          };
          Cookies.set(
            aiDomainConfigs.USER_STATE,
            JSON.stringify(userData),
            { domain: aiDomainConfigs.COOKIE_DOMAIN },
            { expires: 15 }
          );
        }
        showAccountActivationRequiredModal();
        // Cookies.set("activating_user", true);
        // window.location.reload();
      } else {
        toast.error(t("something_went_wrong"), {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(t("error_500"), {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const ModalCancelButton = withStyles((theme) => ({
    root: {
      color: "#3C4043",
      backgroundColor: "#EBEBEB",
      boxShadow: "none",
      borderRadius: "2px",
      marginLeft: "12px",
      textTransform: "none",
      padding: "11px 22px",
      "&:hover": {
        color: "#3C4043",
        backgroundColor: "#d7d7d7",
        boxShadow: "none",
      },
    },
  }))(Button);

  const ModalConfirmButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#0078D4",
      boxShadow: "none",
      borderRadius: "2px",
      textTransform: "none",
      padding: "11px 22px",
      "&:hover": {
        backgroundColor: "#0265b1",
        boxShadow: "none",
      },
    },
  }))(Button);

  return (
    <React.Fragment>
      <Rodal
        {...props}
        {...modaloption}
        visible={props.show}
        showCloseButton={false}
        className="account-deactivation-and-deletion"
      >
        <div className="new-modal-close-btn" onClick={props.onHide}>
          <img
            src={
              Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/icons/close_black.svg"
            }
            alt="close_black"
          />
        </div>
        <div className="active-deactive-modal-body">
          <div className="active-deactive-content-box">
            {/* <p>{t("deactivate_account_message_1")}</p> */}
            {/* <p>{t("deactivate_account_message_2")}</p> */}
            <img
              src={
                Config.aiDomainConfigs.CDN_DOMAIN +
                "/assets/images/icons/account-not-active.svg"
              }
              alt="account-not-active"
            />
            {/* <p>
            <Trans>activate_account_message_1</Trans>
          </p> */}
            <h3>{t("inactive_account_header")}</h3>
            <p>{t("inactive_account_description")}</p>
          </div>
          <div className="active-deactive-button-box">
            <>
              <ModalConfirmButton
                disabled={disableSaveButton}
                onClick={handleAccountActivation}
              >
                <span className="active-deactive-modal-btn-white-txt">
                  {t("yes")}
                </span>
              </ModalConfirmButton>
              <ModalCancelButton onClick={props.onHide}>
                <span className="active-deactive-modal-btn-txt">{t("no")}</span>
              </ModalCancelButton>
            </>
          </div>
        </div>
      </Rodal>
      <AccountActivated
        title={title}
        show={activatedModalShow}
        onHide={() => setActivatedModalShow(false)}
      />
    </React.Fragment>
  );
};

export default ActivateAccount;
