import React from 'react';
import HomeHeroImg from './home-accordian-animation/hero_image.json'
import Lottie from 'react-lottie'


const HomeHeroimg = () => {

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: HomeHeroImg,
        rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
        },
    }

    return ( 
        <div className='home-hero-img-wrapper'> 
            <Lottie options={defaultOptions} isClickToPauseDisabled={true}/>
        </div>
    )
}

export default HomeHeroimg