import React, { useEffect, useState, useRef, useCallback} from "react";
import { Route, Switch, useLocation, Redirect, useHistory } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import AnimationLoader from "../src/loader/animation-loader";
import Config, { aiApiConfigs, aiDomainConfigs } from "./config/Config";
import { checkSubscription, getProfileImages, getTokensFromCookies, getUserInfo, hasAccess, protect } from "./helper/ProtectedCallHelper";
import { useTranslation } from "react-i18next";
import AllRoutes from "./routes/AllRoutes";
import Container from "@material-ui/core/Container";
import { BeatLoader, ClimbingBoxLoader, HashLoader, RotateLoader, ScaleLoader } from "react-spinners";
import { setUserCurrentSubscriptionPlanConfigs } from "./config/PlanConfig";
import Cookies from "js-cookie";
import ButtonBase from "@material-ui/core/ButtonBase";
import { BroadcastChannel } from "broadcast-channel";
import ScrollToTop from "./ScrollUp";
import AOS from "aos";
import "aos/dist/aos.css";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import PageOnload from "./modals/PageOnloadModal";
import CloseIcon from '@material-ui/icons/Close';
import LoadConfetti from "./animation/LoadConfetti";
import CreditsOnload from "./modals/CreditsOnloadModal";
import { CacheBuster } from './CacheBuster';
import packageJson from '../package.json'

export const UserContext = React.createContext(null);

function App(props) {
    const API_URL = aiApiConfigs.API_URL;
    const ACCESS_TOKEN_EXPIRATION_TIME = 800500;
    const permission_tokens = getTokensFromCookies();

    const history = useHistory();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const channel = React.useMemo(() => new BroadcastChannel("logout"), []);
    let admin_email = "admin@ailaysa.com";

    let accessToken = permission_tokens?.token;
    let refreshToken = permission_tokens?.refresh_token;

    const [userInfo, setUserInfo] = useState([]);
    const [profileImages, setProfileImages] = useState([]);
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isErrored, setErrored] = useState(false);
    const [logout, setLogout] = useState(false);
    const [isAwardRecognitionModalShow, setIsAwardRecognitionModalShow] = useState(true);
    const [AwardRecognitionImageSeen, setAwardRecognitionImageSeen] = useState(true);
    const [confettiShow, setConfettiShow] = useState(false);

    const awardModalClickOutside = useRef();

    let error_statement = t("error_500").toUpperCase();
    const [isCookieAccepted, setIsCookieAccepted] = useState(true);

    const handleAwardCloseModal = () => {
        // Cookies.set("isAwardImageSeen", true, { domain: process.env.REACT_APP_COOKIE_DOMAIN, expires: 365 * 5 });
        setAwardRecognitionImageSeen(true)
        setIsAwardRecognitionModalShow(true)
    }

    const handleAwardModalCloseEsc = () => {
        // Cookies.set("isAwardImageSeen", true, { domain: process.env.REACT_APP_COOKIE_DOMAIN, expires: 365 * 5 });
        setAwardRecognitionImageSeen(true)
        setIsAwardRecognitionModalShow(true)
    }

      //close modal on ecs key press
    const keyPress = useCallback(
        (e) => {
        if (e.which === 27) {
            // Cookies.set("isAwardImageSeen", true, { domain: process.env.REACT_APP_COOKIE_DOMAIN, expires: 365 * 5 });
            setAwardRecognitionImageSeen(true)
            setIsAwardRecognitionModalShow(true)
        }
        },
        [setIsAwardRecognitionModalShow, isAwardRecognitionModalShow]
    );

    useEffect(() => {
        document.addEventListener("keydown", keyPress);
        return () => document.removeEventListener("keydown", keyPress);
    }, [keyPress]);


    /* Check for clicing outside of the dropdown */
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (awardModalClickOutside.current && !awardModalClickOutside.current.contains(e.target)) {
                // Cookies.set("isAwardImageSeen", true, { domain: process.env.REACT_APP_COOKIE_DOMAIN, expires: 365 * 5 });
                setAwardRecognitionImageSeen(true)
                setIsAwardRecognitionModalShow(true)
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });
 
    useEffect(() => {
        AOS.init({
            once: true
        })
    }, []);

    useEffect(() => {
        if (typeof Cookies.get("isAwardImageSeen") == "undefined"){
            setAwardRecognitionImageSeen(false)
        }
    }, []);

    

    useEffect(() => {
        if (!isAuthenticated && window.location.pathname == "/" || window.location.pathname.includes("/pricing")) {
            setAwardRecognitionImageSeen(false)
        }
    }, [window.location.pathname]);

    useEffect(() => {
        const interval = setInterval(() => {
            if(!AwardRecognitionImageSeen && !isAuthenticated && (window.location.pathname == "/" || window.location.pathname.includes("/pricing"))){
                setIsAwardRecognitionModalShow(false)
            }
        }, 2100);
    
        return () => clearInterval(interval);
    }, [AwardRecognitionImageSeen, window.location.pathname]);

    useEffect(() => {
        channel.onmessage = (message) => setLogout(message?.logout);
        if (typeof Cookies.get("cookieAccepted") == "undefined") setIsCookieAccepted(false);
    }, []);


    // const modaloption = {
    //     closeMaskOnClick: false,
    //     onClose: handleAwardCloseModal,
    // };

    useEffect(() => {
        if (logout) {
            Cookies.set("global_logout", true, {
                domain: aiDomainConfigs.COOKIE_DOMAIN,
            });
            window.location.href = aiDomainConfigs.BASE_DOMAIN;
        }
    }, [logout]);

    const acceptCookie = () => {
        Cookies.set("cookieAccepted", true, {
            domain: aiDomainConfigs.COOKIE_DOMAIN,
            expires: 30, // no. of days to expire 
        });
        setIsCookieAccepted(true);
    };

    useEffect(() => {
        protect(accessToken, refreshToken);
        setInterval(() => {
            protect(accessToken, refreshToken);
        }, ACCESS_TOKEN_EXPIRATION_TIME);
    }, [accessToken]);

    useEffect(async () => {
        hasAccess(accessToken, refreshToken)
            .then((res) => (res !== undefined ? setAccessables() : setRedirection()))
            .catch((e) => console.log(e));
    }, []);

    useEffect(async () => {
        if (isAuthenticated) {
            try {
                let check_subscription = await checkSubscription(accessToken);
                setUserCurrentSubscriptionPlanConfigs(check_subscription);
            } catch (error) {
                console.log(error);
            }
        }
    }, [localStorage, isAuthenticated]);

    const setAccessables = () => {
        setAuthenticated(true);
        getUserInfo(accessToken)
            .then((res) => {
                res?.pk && setUserInfo(res)
                //  : setErrored(true);
            })
            .catch((err) => {
                window.location.href = aiDomainConfigs.AUTH_DOMAIN;
                // setErrored(true);
            });
        getProfileImages(accessToken)
            .then((res) => setProfileImages(res))
            .catch((e) => console.log(e));
    };

    const setRedirection = () => {
        setAuthenticated(false);
        // window.location.href = aiDomainConfigs.AUTH_DOMAIN;
        // history.push("/login");
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setConfettiShow(true)
        }, 1500);
    
        return () => clearInterval(interval);
      }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setConfettiShow(true)
        }, 1500);

        return () => clearInterval(interval);
    }, []);
    

    useEffect(() => {
        const loadData = async () => {
            await new Promise((r) => setTimeout(r, 1000));
            setLoading((loading) => !loading);
        };
        loadData();
    }, []);
    return (
        <React.Fragment>
            <CacheBuster
                currentVersion={packageJson?.version}
                isEnabled={true}
            />
            <ScrollToTop />
            {isErrored === false ? (
                <UserContext.Provider
                    value={{
                        userInfo,
                        profileImages,
                        accessToken,
                        refreshToken,
                        permission_tokens,
                    }}
                >
                    <AllRoutes />
                </UserContext.Provider>
            ) : (
                <div>
                    <Container className="p-4">
                        <div className="d-flex">
                            <h2 style={{ fontSize: 230 }}>{t("500")}</h2>
                            <div className="mt-3">
                                <p className="mt-5 ml-3" style={{ fontSize: 30 }}>
                                    {error_statement}
                                </p>
                                <>
                                    <p className="pl-3">{t("error_500_serious_statement_1")}</p>
                                    <p className="pl-3">{t("error_500_serious_statement_2", { admin_email })}</p>
                                </>
                            </div>
                        </div>
                        <p className="pl-3">{t("error_500_serious_statement_3")}</p>
                    </Container>
                </div>
            )}
            {!isCookieAccepted && (
                <div className="container ai-cookie-policy-section-blur-effect ai-cookie-policy-container active-anim">
                    <div className="ai-cookie-policy-section">
                        <div className="ai-cookie-policy-cont">
                            <div className="mob-ai-cookie-policy-cont">
                                <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/icons/cookie-icon.svg"} alt="cookie-icon" />
                                <div className="mob-content-align">
                                    {/* <h1>Cookies And Privacy</h1> */}
                                    <p>{t("cookie_acceptance_message")}</p>
                                </div>
                            </div>
                            <ButtonBase onClick={() => acceptCookie()}>
                                <div className="ai-cookie-button">
                                    {t("accept_all_cookies")}
                                </div>
                            </ButtonBase>
                        </div>
                    </div>
                </div>
            )}
            {/* {
                (!isAwardRecognitionModalShow && !isAuthenticated && (window.location.pathname == "/" || window.location.pathname.includes("/pricing"))) &&
                <div className="award-recognition-modal credist-modal-wrapper">
                    <div className="award-recognition-modal-bg credist-modal"></div>
                    <div className="award-recognition-modal-wrapper" ref={awardModalClickOutside}>
                        <div className="award-recognition-modal-inner-wrapper">
                            <div className="ai-modal-header">
                                <span className="modal-close-btn" onClick={handleAwardCloseModal}>
                                    <CloseIcon className="close-icon"/>
                                </span>
                            </div>
                            <CreditsOnload handleAwardCloseModal={handleAwardCloseModal}/>
                            <PageOnload />
                            {
                                confettiShow &&
                                <div className="ai-modal-confetti-wrap">
                                    <LoadConfetti />
                                </div>
                            }

                        </div>
                    </div>
                </div>
            } */}

        </React.Fragment>
    );
}

export default App;
