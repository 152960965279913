import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { Nav, Navbar } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { motion } from "framer-motion";
import { getPlanDetailsFromCookies, hasAccess, setRequestSource } from "../helper/ProtectedCallHelper";
import { useTranslation } from "react-i18next";
import UserAvatar from "./UserAvatar";
import { UserContext } from "../App";
import Config, { aiDomainConfigs, aiApiConfigs } from "../config/Config";
import ButtonBase from "@material-ui/core/ButtonBase";

import ActivateAccount from "../modals/ActivateModal";
import ShowModal from "./ShowModal";
import { useSelector } from "react-redux";

function Header(props) {
    const { t } = useTranslation();
    const user = useContext(UserContext);
    const domNode = useRef();
    const history = useHistory();
    let planState = getPlanDetailsFromCookies();
    const entryPoint1 = "signup";
    const entryPoint2 = "login";
    const { modal, pending, error } = useSelector((state) => state.showModal);

    const [isAuthenticated, setAuthenticated] = useState(false);
    const [navbar, setNavbar] = useState(false);
    const [modalCondition, setModalCondition] = useState(false);
    const [hamburgertoggle, setHamburgerToggle] = useState(false);
    const [mobDrptoggle, setMobDrpToggle] = useState(false);
    const [logoutDrpVisibility, setLogoutDrpVisibility] = useState(false);
    const [windowScroll, setWindowScroll] = useState(0);
    const [planExpiryDays, setPlanExpiryDays] = useState(0);
    const [planExpiryHours, setPlanExpiryHours] = useState(0);
    const username = Cookies.get(aiDomainConfigs.USER_STATE) && JSON.parse(Cookies.get(aiDomainConfigs.USER_STATE))?.name;

    const user_status = !user?.userInfo?.deactivate;
    const currentPlan = planState?.ai_current_plan;
    const planStatus = planState?.ai_plan_status;
    const isTrialPlan = planState?.ai_plan_is_trial;

    const API_URL = aiApiConfigs.API_URL;

    useEffect(() => {
        hasAccess(
            Cookies.get(aiDomainConfigs.USER_STATE) && JSON.parse(Cookies.get(aiDomainConfigs.USER_STATE))?.token,
            Cookies.get(aiDomainConfigs.USER_STATE) && JSON.parse(Cookies.get(aiDomainConfigs.USER_STATE))?.refresh_token
        ).then((res) => (res !== undefined ? setAuthenticated(true) : setAuthenticated(false)));
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", hideDropDownOnScroll);
        return () => window.removeEventListener("scroll", hideDropDownOnScroll);
    }, []);

    useEffect(() => {
        let todaysDate = new Date();
        let planExpiry = new Date(planState?.ai_plan_expiry);

        let timeDifference = planExpiry.getTime() - todaysDate.getTime();
        let dateDifference = timeDifference / (1000 * 3600 * 24);
        let hoursDifference = Math.abs(timeDifference) / 36e5;
        setPlanExpiryDays(Math.ceil(dateDifference));
        setPlanExpiryHours(Math.floor(hoursDifference));
    }, [planState?.ai_plan_expiry]);

    useEffect(() => {
        if (logoutDrpVisibility) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [logoutDrpVisibility]);

    const handleLogoutDrpVisibility = () => {
        setLogoutDrpVisibility(!logoutDrpVisibility);
    };

    const handleClickOutside = (e) => {
        if (domNode.current.contains(e.target)) {
            return;
        }
        setLogoutDrpVisibility(false);
    };

    const logout = () => {
        history.push("/logout");
    };

    const Togglehamburger = () => {
        setHamburgerToggle(!hamburgertoggle);
        document.body.classList.toggle("overflow-hidden");
    };

    const HandleMobCloseCont = () => {
        setHamburgerToggle(!hamburgertoggle);
        document.body.classList.remove("overflow-hidden");
    };

    const HandleMobDrpShow = () => {
        setMobDrpToggle(!mobDrptoggle);
    };

    const changeNavbarBackground = () => {
        if (window.scrollY >= 100) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    const hideDropDownOnScroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        if (winScroll !== document.body.scrollTop || winScroll !== document.documentElement.scrollTop) {
            setLogoutDrpVisibility(false);
        }
    };

    const openLoginURL = () => {
        Cookies.remove("aiFeaturedValue", {
            domain: aiDomainConfigs.COOKIE_DOMAIN,
        });
        setRequestSource();
        window.location.href = aiDomainConfigs.AUTH_DOMAIN;
    };

    const handleSignupBtn = (e) => {
        // e.preventDefault();
        // history.push({ pathname: "/login", state: entryPoint1 });
        Cookies.remove("aiFeaturedValue", {
            domain: aiDomainConfigs.COOKIE_DOMAIN,
        });
        setRequestSource();
        window.location.href = aiDomainConfigs.AUTH_DOMAIN + "/signup";
        // window.location.href = aiDomainConfigs.AUTH_DOMAIN + "/vendor-signup";
    };

    const handleVendorSignupBtn = (e) => {
        setRequestSource();
        window.location.href = aiDomainConfigs.AUTH_DOMAIN + "/vendor-signup";
    }

    window.addEventListener("scroll", changeNavbarBackground);

    const AiNavButton = withStyles((theme) => ({
        root: {
            backgroundColor: "#0078D4",
            boxShadow: "none",
            borderRadius: "2px",
            textTransform: "none",
            padding: 0,
            "&:hover": {
                backgroundColor: "#0265b1",
                boxShadow: "none",
            },
        },
    }))(Button);

    useEffect(() => {
        if (modal?.visible) {
            setModalShow(modal?.visible);
        }
    }, [modal?.visible]);

    const showAccountActivationRequiredModal = () => {
        setModalCondition(true);
    };

    const setModalShow = (value) => {
        setModalCondition(value);
    };

    return (
        <React.Fragment>
            <header
                id="navbar"
                className={
                    navbar
                        ? "ai-navbar ai-sticky-nav navbar-bg-color navbar-shadow navbar-transform-show"
                        : isAuthenticated
                        ? "navbar-transform ai-sticky-nav navbar-transform-show-mob navbar-shadow-mob ai-navbar-mob navbar-bg-color-mob"
                        : "navbar-transform ai-sticky-nav navbar-transform-show-mob navbar-shadow-mob ai-navbar-mob-new navbar-bg-color-mob"
                }
            >
                <div className="container ai-nav-container">
                    <div
                        className={isAuthenticated ? "ai-home-main-authenticated-logo" : "ai-home-main-unauthenticated-logo"}
                        // style={{ marginRight: !isAuthenticated ? "150px" : "-60px" }}
                    >
                        <Link to="/">
                            <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/ailaysa-logo.svg"} alt="Ailaysa-Logo" />
                        </Link>
                    </div>
                    <div className="ai-nav-links nav-mob-hide">
                        <ul className="ai-links-nav-wrap">
                            <li>
                                <NavLink exact activeClassName="nav-active" to="/product">
                                    {t("foo_product")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="nav-active" to="/benefits">
                                    {t("foo_benefits")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="nav-active" to="/pricing">
                                    {t("foo_pricing")}
                                </NavLink>
                            </li>
                            <li className="submenu-link">
                                <a href="#">{t("foo_resources")}</a>
                                <ul className="ai-header-submenu">
                                    <li>
                                        <a rel="noreferrer" target="_blank" href="https://helpcenter.ailaysa.com/">
                                            {t("knowledgebase")}
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a rel="noreferrer" target="_blank" href="#">
                                            {t("blog")}
                                        </a>
                                    </li> */}
                                </ul>
                            </li>
                            <li>
                                <NavLink activeClassName="nav-active" to="/support">
                                    {t("foo_support")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="nav-active" to="/become-an-editor">
                                    {t("for_vendor")}
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="ai-nav-last-links">
                        <ul className="ai-last-links-nav-wrap">
                            {/* <li><a href="#"><span className="icon-nav-hover"><img src={Config.aiDomainConfigs.CDN_DOMAIN+"/assets/images/apps_black.svg"} alt="apps"/></span></a></li>
                            <li><a href="#"><span className="icon-nav-hover"><img src={Config.aiDomainConfigs.CDN_DOMAIN+"/assets/images/global.svg"} alt="global"/></span><span>EN</span></a></li> */}
                            <li className="d-flex">
                                {isAuthenticated ? (
                                    <div className="top-header" onClick={(e) => handleLogoutDrpVisibility(!logoutDrpVisibility)}>
                                        <div className="avatar-white-link">
                                            <UserAvatar sizeProp="32" className="nav-item nav-drp-down active" />
                                        </div>

                                        {logoutDrpVisibility ? (
                                            <ul
                                                ref={domNode}
                                                // className="submenu-1 submenu-animated submenu_fadeIn"
                                                className={
                                                    logoutDrpVisibility
                                                        ? "submenu-1 submenu-1-active submenu-animated submenu_fadeIn"
                                                        : "submenu-1 submenu-animated submenu_fadeIn"
                                                }
                                            >
                                                <li className="acc-details">
                                                    <div className="acc-details-profile">
                                                        {user?.profileImages?.avatar ? (
                                                            <img className="drp-down-profile-icon" src={API_URL + user?.profileImages?.avatar} alt="profile-pic" />
                                                        ) : (
                                                            <div className="drp-down-no-profile-icon">{user?.userInfo?.fullname?.charAt(0)?.toUpperCase()}</div>
                                                        )}
                                                    </div>
                                                    <div className="acc-member-info">
                                                        <p className="name">{user?.userInfo?.fullname}</p>
                                                        <p className="email">{user?.userInfo?.email}</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <a target="_blank" rel="noreferrer" href={aiDomainConfigs.HOST_DOMAIN}>{t("my_account")}</a>
                                                    <a target="_blank" rel="noreferrer" href={aiDomainConfigs.TRANSEDITOR_DOMAIN}>{t("transeditor")}</a>
                                                    <a target="_blank" rel="noreferrer" href={aiDomainConfigs.MARKETPLACE_DOMAIN}>{t("marketplace")}</a>
                                                    <a target="_blank" rel="noreferrer" href={aiDomainConfigs.TRANSEDITOR_DOMAIN + "/word-processor"}>Writer</a>
                                                </li>

                                                {user_status ? (
                                                    planStatus === "active" && planExpiryDays <= 3 ? (
                                                        <li>
                                                            <div className="submenu-plan-details ">
                                                                <p className="plan-details">
                                                                    {currentPlan.toUpperCase()}{" "}
                                                                    <span>
                                                                        {planExpiryDays < 1
                                                                            ? planExpiryHours < 1
                                                                                ? t("plan_expires_in_hour", {
                                                                                      planExpiryHours,
                                                                                  })
                                                                                : t("plan_expires_in_hours", {
                                                                                      planExpiryHours,
                                                                                  })
                                                                            : t("plan_expires_in_days", {
                                                                                  planExpiryDays,
                                                                              })}
                                                                    </span>
                                                                </p>
                                                                <div className="drp-down-link"
                                                                    onClick={() =>
                                                                        (window.location.href =
                                                                            aiDomainConfigs.HOST_DOMAIN + "/dashboard?request_for=previous_plan_renewal")
                                                                    }
                                                                >
                                                                    <p className="new-head">{t("renew").toUpperCase()}</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ) : planStatus === "trialing" ? (
                                                        <li>
                                                            <div className="submenu-plan-details ">
                                                                <p className="plan-details">
                                                                    {currentPlan.toUpperCase()}{" "}
                                                                    <span>
                                                                        {planExpiryDays < 1
                                                                            ? planExpiryHours < 1
                                                                                ? t("trial_expires_in_hour", {
                                                                                      planExpiryHours,
                                                                                  })
                                                                                : t("trial_expires_in_hours", {
                                                                                      planExpiryHours,
                                                                                  })
                                                                            : t("trial_expires_in_days", {
                                                                                  planExpiryDays,
                                                                              })}
                                                                    </span>
                                                                </p>
                                                                <Link to="/pricing">
                                                                    <p className="new-head">{t("subscribe").toUpperCase()}</p>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                    ) : planStatus === "canceled" ? (
                                                        <li>
                                                            <div className="submenu-plan-details ">
                                                                <p className="plan-details">
                                                                    {currentPlan.toUpperCase()} <span>{t("plan_expired")}</span>
                                                                </p>
                                                                {isTrialPlan === "true" ? (
                                                                    <Link to="/pricing">
                                                                        <p className="new-head">{t("subscribe").toUpperCase()}</p>
                                                                    </Link>
                                                                ) : (
                                                                    <div className="drp-down-link"
                                                                        onClick={() =>
                                                                            (window.location.href =
                                                                                aiDomainConfigs.HOST_DOMAIN + "/dashboard?request_for=previous_plan_renewal")
                                                                        }
                                                                    >
                                                                        <p className="new-head">{t("renew").toUpperCase()}</p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </li>
                                                    ) : planStatus === "past_due" ? (
                                                        <li>
                                                            <div className="submenu-plan-details ">
                                                                <p className="plan-details">
                                                                    {currentPlan.toUpperCase()} <span>{t("plan_ended")}</span>
                                                                </p>
                                                                {isTrialPlan === "true" ? (
                                                                    <Link to="/pricing">
                                                                        <p className="new-head">{t("subscribe").toUpperCase()}</p>
                                                                    </Link>
                                                                ) : (
                                                                    <div className="drp-down-link"
                                                                        onClick={() =>
                                                                            (window.location.href =
                                                                                aiDomainConfigs.HOST_DOMAIN + "/dashboard?request_for=previous_plan_renewal")
                                                                        }
                                                                    >
                                                                        <p className="new-head">{t("renew").toUpperCase()}</p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </li>
                                                    ) : null
                                                ) : (
                                                    <li style={{ cursor: "pointer" }} onClick={() => showAccountActivationRequiredModal()}>
                                                        <div className="submenu-plan-details">
                                                            <p className="plan-details">
                                                                {currentPlan.toUpperCase()} <span>{t("account_deactivated_status")}</span>
                                                            </p>
                                                        </div>
                                                    </li>
                                                )}
                                                <li>
                                                    <a className="logout-cls" href="#!" onClick={(e) => logout()}>
                                                        {t("logout")}
                                                    </a>
                                                </li>
                                            </ul>
                                        ) : null}
                                    </div>
                                ) : (
                                    <div className="add-d-flex mob-link-hide signup-gap align-items-center">
                                        {/* <ButtonBase> */}
                                        <div onClick={(e) => openLoginURL(e)} className="new-login-btn">
                                            {t("login")}
                                        </div>
                                        {/* </ButtonBase> */}
                                        <div onClick={(e) => handleSignupBtn(e)}>
                                            <AiNavButton>
                                                <span className="login-btn">
                                                    {t("signup")} - <span className="free-txt">{t("its_free")}</span>
                                                    {/* <span className="free-txt">{t("im_vendor")}</span> */}
                                                </span>
                                            </AiNavButton>
                                        </div>
                                    </div>
                                )}
                            </li>
                            <li className="common-menu-bar">
                                <div onClick={Togglehamburger} className={hamburgertoggle ? "ai-menubar is-active" : "ai-menubar"} id="ai-hamburger-menu">
                                    <span className="line"></span>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
            <div className={hamburgertoggle ? "mob-menu-sections-show" : "mob-menu-sections-hide"}>
                <div className="mob-main-logo">
                    <Link to="/">
                        <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/ailaysa-logo.svg"} alt="Ailaysa-Logo" />
                    </Link>
                    <span onClick={HandleMobCloseCont} className="menu-close-delete">
                        <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/icons/menu-close-icon.svg"} alt="delete" />
                    </span>
                </div>
                <ul className="mob-menu-part">
                    <li>
                        <NavLink onClick={HandleMobCloseCont} exact className="mob-arrow-links" activeClassName="nav-active" to="/product">
                            {t("foo_product")}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="mob-arrow-links" onClick={HandleMobCloseCont} activeClassName="nav-active" to="/benefits">
                            {t("foo_benefits")}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="mob-arrow-links" onClick={HandleMobCloseCont} activeClassName="nav-active" to="/pricing">
                            {t("foo_pricing")}
                        </NavLink>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between align-items-center drp-down-link" onClick={HandleMobDrpShow}>
                            <a className={mobDrptoggle ? "mob-drp-active-color mob-drp-arrow-links" : "mob-drp-arrow-links"} href="javascrit:void(0);">
                                {t("foo_resources")}
                            </a>{" "}
                            <span>
                                <i className={mobDrptoggle ? "arrow-align fas fa-chevron-right mob-drp-active-color" : "arrow-align fas fa-chevron-down"}></i>
                            </span>
                        </div>
                        <ul
                            className={
                                mobDrptoggle
                                ? "ai-mob-header-submenu mob-submenu-open"
                                : "ai-mob-header-submenu mob-submenu-close"
                            }
                        >
                            <li>
                                <a
                                target="_blank"
                                rel="noreferrer"
                                onClick={HandleMobCloseCont}
                                href="https://helpcenter.ailaysa.com/"
                                >
                                {t("knowledgebase")}
                                </a>
                            </li>
                            {/* <li><a target="_blank" href="#">Blog</a></li> */}
                        </ul>
                    </li>
                    <li>
                        <NavLink className="mob-arrow-links" onClick={HandleMobCloseCont} activeClassName="nav-active" to="/support">
                            {t("foo_support")}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="mob-arrow-links" onClick={HandleMobCloseCont} activeClassName="nav-active" to="/become-an-editor">
                            {t("for_vendor")}
                        </NavLink>
                    </li>
                </ul>
                {!isAuthenticated ? (
                    <div className="mob-menu-auth-link">
                        <div className="btn-width-100 d-flex">
                            {/* <ButtonBase> */}
                            <div onClick={(e) => openLoginURL(e)} className="new-login-btn">
                                {t("login")}
                            </div>
                            {/* </ButtonBase> */}
                            <div className="sign-up-link" onClick={handleSignupBtn}>
                                <AiNavButton>
                                    <span className="login-btn">
                                        {t("signup")} - <span className="free-txt">{t("its_free")}</span>
                                        {/* <span className="free-txt">{t("im_vendor")}</span> */}
                                    </span>
                                </AiNavButton>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
            <ShowModal condition={modalCondition} setModalShow={setModalShow} />
            {/* <AccountActivated
        title={title}
        show={activatedModalShow}
        onHide={() => setActivatedModalShow(false)}
      /> */}
        </React.Fragment>
    );
}

export default Header;
