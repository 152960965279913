import React from 'react';
import homeAccordianone from './home-accordian-animation/homeAccord_1.json'
import Lottie from 'react-lottie'


const HomeAccordianOne = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: homeAccordianone,
        rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
        },
    }

    return ( 
        <div className='home-accordian-img-wrapper'> 
            <Lottie options={defaultOptions} isClickToPauseDisabled={true}/>
        </div>
    )
}

export default HomeAccordianOne