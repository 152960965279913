import React from "react";
import Config from "../../config/Config";

function BtnSlider({ direction, moveSlide }) {
  // console.log(direction, moveSlide);
  return (
    <button
      onClick={moveSlide}
      className={direction === "next" ? "btn-slide next" : "btn-slide prev"}
    >
      <img
        src={
          direction === "next"
            ? Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/expand_up.svg"
            : Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/expand_down.svg"
        }
      />
    </button>
  );
}

export default BtnSlider;
