import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import LaunchDayCountDown from "./LaunchDayCountDown";
import Config from "../config/Config";

export const AiNavButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#0078D4",
    boxShadow: "none",
    borderRadius: "2px",
    textTransform: "none",
    padding: 0,
    "&:hover": {
      backgroundColor: "#0265b1",
      boxShadow: "none",
    },
  },
}))(Button);

function LandingCommonHeader(props) {

  return (
    <React.Fragment>
      <header className="ai-navbar ai-common-navbar landing-common-header">
        <div className="container ai-nav-container">
          <div className="landing-common-header-row">
            <div className="main-logo">
              <Link to="/">
                <img
                  src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/ailaysa-logo.svg"}
                  alt="Ailaysa-Logo"
                />
              </Link>
            </div>
            <div className="timer-wrapper">
                <LaunchDayCountDown />
            </div>
            {/* <div className="landing-nav-btn">
              <AiNavButton>
                <span className="ai-landing-btn-txt">Sign Up <span>- It&apos;s FREE</span></span>
              </AiNavButton>
            </div> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

export default LandingCommonHeader;
