import Cookies from "js-cookie";
import { aiDomainConfigs } from "./Config";

export const setUserCurrentSubscriptionPlanConfigs = (check_subscription) => {
  let current_subscription = {};

  let planData = {
    ai_current_plan: check_subscription?.data?.subscription_name,
    ai_plan_status: check_subscription?.data?.sub_status,
    ai_plan_is_trial: check_subscription?.data?.trial,
  };

  if (
    check_subscription?.data?.sub_status === "active" ||
    check_subscription?.data?.sub_status === "trialing"
  ) {
    planData = {
      ...planData,
      ai_payment_currency: check_subscription?.data?.sub_currency,
      ai_paid_amount: check_subscription?.data?.sub_amount,
      ai_paid_interval: check_subscription?.data?.interval,
      ai_plan_expiry: check_subscription?.data?.sub_period_end,
      ai_payment_required: false,
    };
  } else if (check_subscription?.data?.sub_status === "canceled") {
    planData = {
      ...planData,
      ai_payment_required: false,
      ai_price_id: check_subscription?.data?.sub_price_id,
    };
  }

  Cookies.set(
    aiDomainConfigs.PLAN_STATE,
    JSON.stringify(planData),
    { domain: aiDomainConfigs.COOKIE_DOMAIN },
    { expires: 15 }
  );

  // check_subscription?.data?.subscription_name &&
  //   localStorage.setItem(
  //     "aiCurrentPlan",
  //     check_subscription?.data?.subscription_name
  //   );

  // check_subscription?.data?.sub_status &&
  //   localStorage.setItem("aiPlanStatus", check_subscription?.data?.sub_status);
  // check_subscription?.data?.trial &&
  //   localStorage.setItem("aiTrialCheck", check_subscription?.data?.trial);

  // if (
  //   check_subscription?.data?.sub_status === "active" ||
  //   check_subscription?.data?.sub_status === "trialing"
  // ) {
  //   check_subscription?.data?.sub_currency &&
  //     localStorage.setItem(
  //       "aiPaymentCurrency",
  //       check_subscription?.data?.sub_currency
  //     );

  //   localStorage.setItem("aiPaidAmount", check_subscription?.data?.sub_amount);

  //   check_subscription?.data?.interval &&
  //     localStorage.setItem(
  //       "aiPlanInterval",
  //       check_subscription?.data?.interval
  //     );

  //   check_subscription?.data?.sub_period_end &&
  //     localStorage.setItem(
  //       "aiPlanExpiry",
  //       check_subscription?.data?.sub_period_end
  //     );

  //   localStorage.setItem("payment_required", false);
  // } else if (check_subscription?.data?.sub_status === "canceled") {
  //   localStorage.setItem("payment_required", true);
  //   localStorage.setItem("aiPriceId", check_subscription?.data?.sub_price_id);

  //   localStorage.removeItem("aiPaidAmount");
  //   localStorage.removeItem("aipaymentCurrency");
  //   localStorage.removeItem("aiPlanInterval");
  //   localStorage.removeItem("aiPlanExpiry");
  //   localStorage.removeItem("aiPaymentCurrency");
  // }
};
