import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { UserContext } from "../App";
import ActivateAccount from "../modals/ActivateModal";
import {
  showModalStart,
  showModalSuccess,
  showModalError,
} from "../redux/slices/showModalSlice";

const ShowModal = ({ condition, setModalShow, say }) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const user_status = !user?.userInfo?.deactivate;
  const [title, setTitle] = useState("");
  const [activateModalShow, setActivateModalShow] = useState(false);

  useEffect(() => {
    if (condition) {
      setTimeout(() => {
        setTitle(t("activate_account"));
        setActivateModalShow(true);
      }, 200);
    }
  }, [condition]);

  useEffect(() => {
    dispatch(showModalStart());
    setModalShow(activateModalShow);
    dispatch(showModalSuccess({ visible: activateModalShow }));
  }, [activateModalShow]);

  //   const showAccountActivationRequiredModal = () => {
  //     setTimeout(() => {
  //       setTitle(t("activate_account"));
  //       setActivateModalShow(true);
  //     }, 200);
  //   };

  return (
    <div>
      <ActivateAccount
        title={title}
        show={activateModalShow}
        onHide={() => setActivateModalShow(false)}
        message={user_status}
      />
    </div>
  );
};

export default ShowModal;
