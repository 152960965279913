import React from "react"



function AnimationLoader(props) {
  return (
    <React.Fragment>
      <section className="animation-logo">
        <div className="svg-logo">
        </div>
      </section>
    </React.Fragment>
  );
}

export default AnimationLoader
