import React, { useState, useEffect, useRef, useLayoutEffect, useContext, lazy, Suspense } from "react";
import { Route, Switch, useLocation, Redirect, useHistory } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

// import Login from "../authentication/Login";
// import ForgotPassword from "../authentication/ForgetPassword";
// import ResetLink from "../authentication/ResetLink";
// import ResetPwd from "../authentication/ResetPassword";
// import ConfirmEmail from "../authentication/ConfirmEmail";
// import EmailVerification from "../authentication/EmailVerification";
// import PasswordUpdated from "../authentication/PasswordUpdated";

import Home from "../pages/Home";
// import Contact from "../pages/Contact";
// import Support from "../pages/Support";
// import BecomeEditor from "../pages/BecomeEditor";
// import Benefits from "../pages/Benefits";
// import Pricing from "../pages/Pricing";
// import NotFound from "../pages/NotFound";
// import Career from "../pages/Career";
// import About from "../pages/About";
// import Product from "../pages/Product";
// import Logout from "../authentication/Logout";
// import EnterpriseSubscription from "../pages/EnterpriseSubscription";
// import PrivacyPolicy from "../pages/PrivacyPolicy";
// import TermsOfService from "../pages/TermsOfService";
import LandingPage from "../pages/LandingPage";
// import ThankYou from "../pages/thank-you/ThankYou";
// import TempProjectSetup from "../pages/try-free/TempProjectSetup"
// import CreditsLandingPage from "../pages/CreditsLandingPage";
import Cookies from "js-cookie";
import Config, { aiApiConfigs, aiDomainConfigs } from "../config/Config";
import { UserContext } from "../App";
import {
    hasAccess,
} from "../helper/ProtectedCallHelper";

// const Artists = lazy(() => import(‘./Artists’))
const Contact = lazy(() => import('../pages/Contact'))
const Support = lazy(() => import('../pages/Support'))
const BecomeEditor = lazy(() => import('../pages/BecomeEditor'))
const Benefits = lazy(() => import('../pages/Benefits'))
const Pricing = lazy(() => import('../pages/Pricing'))
const NotFound = lazy(() => import('../pages/NotFound'))
const Career = lazy(() => import('../pages/Career'))
const About = lazy(() => import('../pages/About'))
const Product = lazy(() => import('../pages/Product'))
const Logout = lazy(() => import('../authentication/Logout'))
const EnterpriseSubscription = lazy(() => import('../pages/EnterpriseSubscription'))
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'))
const TermsOfService = lazy(() => import('../pages/TermsOfService'))
const ThankYou = lazy(() => import('../pages/thank-you/ThankYou'))
const TempProjectSetup = lazy(() => import('../pages/try-free/TempProjectSetup'))
const CreditsLandingPage = lazy(() => import('../pages/CreditsLandingPage'))

const AllRoutes = () => {
    const location = useLocation();
    const history = useHistory();

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        hasAccess(
            Cookies.get(aiDomainConfigs.USER_STATE) && JSON.parse(Cookies.get(aiDomainConfigs.USER_STATE))?.token,
            Cookies.get(aiDomainConfigs.USER_STATE) && JSON.parse(Cookies.get(aiDomainConfigs.USER_STATE))?.refresh_token
        ).then((res) => (res !== undefined ? setIsAuthenticated(true) : setIsAuthenticated(false)));
    }, []);
    


    return (
        <AnimatePresence exitBeforeEnter initial={false}>
            <Switch location={location} key={location.pathname}>
                <Route exact path="/" component={Home} />
                <Route exact path="/home" component={Home} />

                {/* 
                <Route path="/login" component={Login} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/reset-link" component={ResetLink} />
                <Route path="/reset-password/:uid/:token" component={ResetPwd} />
                <Route path="/confirm-email/:token" component={ConfirmEmail} />
                <Route path="/email-verification" component={EmailVerification} />
                <Route path="/password-updated" component={PasswordUpdated} /> 
                */}

                <Suspense >
                    <Switch location={location} key={location.pathname}>
                        <Route path="/benefits" component={Benefits} />
                        <Route path="/product" component={Product} />
                        <Route path="/pricing" component={Pricing} />
                        <Route path="/support" component={Support} />
                        <Route path="/become-an-editor" component={BecomeEditor} />
                        <Route path="/career" component={Career} />
                        <Route path="/about" component={About} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/privacy-policy" component={PrivacyPolicy} />
                        <Route path="/terms-of-service" component={TermsOfService} />
                        <Route path="/enterprise-subscription" component={EnterpriseSubscription}></Route>
                        <Route path="/logout" component={Logout}></Route>
                        {/* <Route path="/free-workshop" component={LandingPage}></Route> */}
                        <Route path="/20000freewords" render={() => isAuthenticated ? <Redirect to="/"/> : <CreditsLandingPage />}></Route>
                        <Route path="/thank-you" component={ThankYou}></Route>
                        <Route path="/try-instant-text" component={TempProjectSetup}></Route>
                        <Route path="/404" component={NotFound} />
                        <Redirect to="/404" />
                    </Switch>
                </Suspense>
            </Switch>
        </AnimatePresence>
    );
};

export default AllRoutes;
