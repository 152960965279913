import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import classnames from "classnames";
import LandingCommonHeader from "../layouts/LandingCommonHeader";
import LandingHeader from "../layouts/LandingHeader";
import SmoothScroll from "../smoothscroll/SmoothScroll";
import { AnimatePresence, motion } from "framer-motion";
import Button from "@material-ui/core/Button";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import { TabContent, TabPane, Nav, NavItem } from "reactstrap";
import { getCountries } from "../helper/ProtectedCallHelper";
import { useTranslation } from "react-i18next";
import validator from "validator";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Config, { aiApiConfigs, aiDomainConfigs } from "../config/Config";
import Cookies from "js-cookie";
import Helmet from "react-helmet";
toast.configure();

export const AiLandingFormButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#0078D4",
    boxShadow: "none",
    borderRadius: "2px",
    textTransform: "none",
    padding: 0,
    marginTop: "25px",
    "&:hover": {
      backgroundColor: "#0265b1",
      boxShadow: "none",
    },
  },
}))(Button);

export const AiLandingInterestedButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#0078D4",
    boxShadow: "none",
    borderRadius: "2px",
    textTransform: "none",
    padding: 0,
    "&:hover": {
      backgroundColor: "#0265b1",
      boxShadow: "none",
    },
  },
}))(Button);

export const AiLandingSignupButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#0078D4",
    boxShadow: "none",
    borderRadius: "2px",
    textTransform: "none",
    padding: 0,
    marginTop: "25px",
    "&:hover": {
      backgroundColor: "#0265b1",
      boxShadow: "none",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "1rem",
  },
  textField: {
    width: "100%",
    borderColor: "#D2D2D2",
  },
  cssLabel: {
    fontWeight: "300",
    fontSize: "15px",
    lineHeight: 1.2,
    fontFamily: '"Inter", sans-serif',
    color: "#A6A6A7",
  },
  cssLabelFocused: {
    fontWeight: "400",
  },
  input: {},
}));

function LandingPage(props) {
  const API_URL = aiApiConfigs.API_URL;
  const { t } = useTranslation();
  const [countries, setCountries] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [addActiveClassAuthor, setActiveClassAuthor] = useState(false);
  const [addActiveClassPublisher, setActiveClassPublisher] = useState(false);
  const [addActiveClassTranslator, setActiveClassTranslator] = useState(false);

  const [countryDetails, setCountryDetails] = useState([]);
  const [countriesList, setCountriesList] = useState([]);

  const [isLoading, setLoading] = useState(true);
  const [isJoining, setJoining] = useState(false);
  const [isErrored, setErrored] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState(null);
  const [password, setPassword] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [requirementSatisfied, setRequirementSatisfied] = useState(true);

  const [emailError, setEmailError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [passwordCriteria, setPasswordCriteria] = useState([]);

  const formSection = useRef();
  const authorSection = useRef();
  const publisherSection = useRef();
  const translatorSection = useRef();

  const pagetransition = {
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  const required = (
    <p className="ai-max-words-validation-new-alert-new">{t("required")}</p>
  );

  useEffect(() => {
    const setAccessables = async () => {
      let access_countries = [];

      try {
        access_countries = await getCountries();
      } catch (e) {
        console.log(e);
      }

      if (access_countries?.length > 0) {
        setCountryDetails(access_countries ? access_countries : null);
        setErrored(false);
      } else {
        setErrored(true);
      }
      setLoading(false);
    };

    setAccessables();
  }, []);

  useEffect(() => {
    setAutoFills();
  }, [isLoading]);

  const setAutoFills = () => {
    let country_options = [];

    countryDetails.map((country) => {
      country_options.push({ value: country.id, label: country.name });
    });

    setCountriesList(country_options);
  };

  const activeToggle = (tab) => {
    if (activeTab != tab) setActiveTab(tab);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setEmail(e.target.value.toLowerCase());
    if (emailError === t("ivalid_email_format") && re.test(email)) {
      setEmailError("");
    }
  };

  const handleCountryChange = (event) => {
    setCountries(event.target.value);
    if (!(event.target.value === undefined)) {
      setCountryError("");
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (validatePassword(e.target.value)) {
      setPasswordCriteria("");
    }
  };

  const validatePassword = (value) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minNumber: 1,
        minLowercase: 1,
        minUppercase: 1,
        minSymbols: 1,
      })
    ) {
      return true;
    } else {
      return false;
    }
  };

  //   useEffect(() => {
  //     window.addEventListener('scroll', listenScrollEvent);

  //     return () =>
  //       window.removeEventListener('scroll', listenScrollEvent);
  //   }, []);

  //   const listenScrollEvent = () => {
  //     if (window.scrollY < authorSection.current) {
  //       return setActiveClassAuthor(true)
  //     } else {
  //       return setActiveClassAuthor(false)
  //     }
  //   }

  const handleScrollToAuthor = (process) => {
    if (process === "click")
      authorSection.current.scrollIntoView({ behavior: "smooth" });
    setActiveClassAuthor(true);
    setActiveClassPublisher(false);
    setActiveClassTranslator(false);
  };

  const handleScrollToTranslators = (process) => {
    if (process === "click")
      translatorSection.current.scrollIntoView({ behavior: "smooth" });
    setActiveClassTranslator(true);
    setActiveClassAuthor(false);
    setActiveClassPublisher(false);
  };

  const handleScrollToPublishers = (process) => {
    if (process === "click")
      publisherSection.current.scrollIntoView({ behavior: "smooth" });
    setActiveClassPublisher(true);
    setActiveClassAuthor(false);
    setActiveClassTranslator(false);
  };

  const handleScrollUp = () => {
    // window.scrollTo(0,0)
    formSection.current.scrollIntoView({ behavior: "smooth" });
  };

  const classes = useStyles();

  // const countriesList = [
  //   {
  //     value: "in",
  //     label: "India",
  //   },
  //   {
  //     value: "uk",
  //     label: "United Kingdom",
  //   },
  //   {
  //     value: "us",
  //     label: "United States",
  //   },
  //   {
  //     value: "An",
  //     label: "Antartica",
  //   },
  // ];

  const CustomHeaderCheckbox = withStyles({
    root: {
      color: "#9B9BA1",
      padding: 5,
      "&:hover": {
        backgroundColor: "#EBEBEB",
      },
      "&$checked": {
        color: "#0078D4",
        "&:hover": {
          backgroundColor: "#E5F1FB",
        },
      },
    },
    checked: {},
  })(Checkbox);

  //   const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 1900 && position < 2390) {
      handleScrollToAuthor();
    } else if (position > 2390 && position < 2980) {
      handleScrollToTranslators();
    } else if (position > 2980 && position < 3400) {
      handleScrollToPublishers();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleJoinClick = () => {
    const validated_data = validateForm();
    validated_data ? callSignupAPI() : null;
  };

  const validateForm = () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      name?.length <= 0 ||
      email?.length <= 0 ||
      !countries ||
      password?.length <= 0 ||
      !agreed
    ) {
      setRequirementSatisfied(false);
      return false;
    } else if (!re.test(email)) {
      setEmailError(t("ivalid_email_format"));
      return false;
    } else {
      setEmailError("");
      setRequirementSatisfied(true);
      return true;
    }
  };

  const callSignupAPI = async () => {
    let signupRequestOptions = {};
    let tempPricingRequestOptions = {};
    let signupCountryPreferrenceRequestOptions = {};

    setJoining(true);
    signupRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fullname: name,
        country: countries,
        email,
        password,
        campaign: "CHBOFA22",
      }),
    };

    signupCountryPreferrenceRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        country: countries,
      }),
    };

    try {
      const signup_response = await fetch(
        API_URL + "/auth/dj-rest-auth/registration/",
        signupRequestOptions
      );

      // const preferred_country_response = await fetch(
      //   API_URL + "/auth/user-app-preference/",
      //   signupCountryPreferrenceRequestOptions
      // );

      const data = await signup_response.json();
      const status = signup_response.status;

      // const countryPreferrenceData = await preferred_country_response.json();
      // const countryPreferrenceStatus = await preferred_country_response.status;

      if (status === 201) {
        Cookies.set("aiNewUser", true, {
          domain: aiDomainConfigs.COOKIE_DOMAIN,
        });

        localStorage.setItem("aiNewUser", "true");
        localStorage.setItem("aiUsername", name);

        props.history.push({
          pathname: "/thank-you",
          email,
        });

        // toast.success(t("email_verification_title", { email }), {
        //   position: toast.POSITION.TOP_CENTER,
        // });
      } else if (status === 400) {
        if (
          data.email &&
          data.email[0] === "ai user with this email address already exists."
        ) {
          toast.error(t("email_already_taken"), {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (data.error) {
          setPasswordCriteria(data.error);
        }
      } else if (status === 500) {
        toast.info(t("error_500"), {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(t("something_went_wrong"), {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(t("server_is_under_construction"), {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setJoining(false);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{t("ailaysa_workshop")}</title>
        <meta name="description" content={t("ailaysa_workshop_desc")} />
      </Helmet>
      {/* <LandingHeader handleScrollUp = {handleScrollUp}/> */}
      {/* <SmoothScroll>
        <AnimatePresence>
          <motion.section
            initial="out"
            animate="in"
            exit="out"
            variants={pagetransition}
          > */}
      <section className="landing-page-wrapper">
        <div ref={formSection} className="ai-landing-banner">
          <LandingCommonHeader />
          <div className="container">
            <div className="ai-landing-row">
              <div className="ai-landing-banner-col">
                <h1 className="main-title">
                  Artificial Intelligence Meets Book Publishing!
                </h1>
                <p className="desc">
                  Join the FREE course on how to use AI to{" "}
                  <span>&quot;translate your book in 7 days.&quot;</span>
                </p>
                <div className="ai-landing-schedule">
                  {/* <div className="ai-landing-timings">
                    <span className="txt">Online -</span>
                    <div className="tile-wrapper">
                      <span className="tile-txt">Weekdays</span>
                      <span className="tile-txt">Weekends</span>
                    </div>
                  </div> */}
                  <div className="ai-landing-timings">
                    <span className="icon">
                      <img
                        src={
                          Config.aiDomainConfigs.CDN_DOMAIN +
                          "/assets/images/landing-page/calendar.svg"
                        }
                        alt="calendar"
                      />
                    </span>
                    <span className="txt">13/03/2022</span>
                  </div>
                  <div className="ai-landing-timings">
                    <span className="icon">
                      <img
                        src={
                          Config.aiDomainConfigs.CDN_DOMAIN +
                          "/assets/images/landing-page/timer.svg"
                        }
                        alt="timer"
                      />
                    </span>
                    <span className="txt">10:30 AM - 12:30 PM</span>
                  </div>
                </div>
              </div>
              <div className="ai-landing-banner-col">
                <div className="landing-banner-form-wrapper">
                  <h4 className="title">
                    Join Ailaysa and take advantage of free training.
                  </h4>
                  <div className="banner-form-wrapper">
                    <TextField
                      className={
                        (classes.textField, "landing-banner-textfield")
                      }
                      id="standard-basic"
                      label="Name"
                      type="text"
                      variant="standard"
                      inputProps={{
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      onChange={handleNameChange}
                    />
                    {!requirementSatisfied && name?.length <= 0
                      ? required
                      : null}
                    <TextField
                      id="standard-select-countries"
                      select
                      label="Country"
                      className={
                        (classes.textField, "landing-banner-textfield")
                      }
                      value={countries}
                      onChange={handleCountryChange}
                      variant="standard"
                    >
                      {countriesList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    {!requirementSatisfied && !countries ? required : null}
                    <TextField
                      className={
                        (classes.textField, "landing-banner-textfield")
                      }
                      id="standard-basic"
                      label="Email"
                      type="email"
                      variant="standard"
                      inputProps={{
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      onChange={handleEmailChange}
                    />
                    {!requirementSatisfied && email?.length <= 0
                      ? required
                      : null}
                    <TextField
                      className={
                        (classes.textField, "landing-banner-textfield")
                      }
                      id="standard-basic"
                      label="Password"
                      type="password"
                      variant="standard"
                      inputProps={{
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      onChange={handlePasswordChange}
                    />
                    {!requirementSatisfied && password?.length <= 0
                      ? required
                      : null}
                    {passwordCriteria && (
                      <h6 className="ai-max-words-validation-new-alert-new">
                        {passwordCriteria?.map((pc, idx) => (
                          <li
                            key={idx}
                            className="ai-max-words-validation-new-alert-new"
                          >
                            {pc}
                          </li>
                        ))}
                      </h6>
                    )}
                    <div className="checkbox-align">
                      <CustomHeaderCheckbox
                        checked={agreed}
                        onChange={() => setAgreed(!agreed)}
                      />
                      <p>
                        I agree to Ailaysa&apos;s{" "}
                        <span>
                          <Link to="/terms-of-service">Terms of Service</Link>
                        </span>{" "}
                        and{" "}
                        <span>
                          <Link to="/privacy-policy">Privacy Policy</Link>
                        </span>
                      </p>
                    </div>
                    {!requirementSatisfied && !agreed ? required : null}
                    <AiLandingFormButton
                      type="submit"
                      onClick={() => handleJoinClick()}
                    >
                      <span className="ai-landing-submit-btn">
                        {isJoining ? "Joining..." : "Join"}
                      </span>
                    </AiLandingFormButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="landing-section-2">
          <div className="container">
            <h1 className="section-2-title">
              உங்கள் புத்தகத்தை மொழிபெயர்த்திடுங்கள்,{" "}
              <span>ஒரே வாரத்தில்!</span>
            </h1>
            <div className="landing-about-senthil">
              <div className="landing-owner-img">
                <img
                  src={
                    Config.aiDomainConfigs.CDN_DOMAIN +
                    "/assets/images/landing-page/senthil.png"
                  }
                  alt="senthil"
                />
              </div>
              <div className="landing-owner-details">
                <h2>ஆழி செந்தில்நாதன்</h2>
                <small>பதிப்பாளர், மொழிபெயர்ப்பாளர், ஐலேசா நிறுவனர்</small>
                <p>
                  &quot;நீங்கள் தமிழ், ஆங்கிலம் என இருமொழிகளிலும் உங்கள்
                  புத்தகங்களை வெளியிடுவதற்கான காலம் வந்துவிட்டது. மொழிபெயர்ப்பு,
                  பதிப்புத் துறைகளில் எங்கள் 20 ஆண்டு கால அனுபவத்தை ஒரு
                  மென்பொருளாக உருவாக்கியிருக்கிறோம். மின்னல்வேக மொழிபெயர்ப்பு,
                  சுலபமான எடிட்டிங், 30,000 சொற்களைக் கொண்ட ஒரு புத்தகத்தை ஒரே
                  வாரத்தில் நீங்கள் மொழிமாற்றம் செய்துவிடலாம். அதுவும் மிக மிக
                  குறைந்த செலவில்.&quot;
                </p>
                <AiLandingInterestedButton onClick={handleScrollUp}>
                  <span className="ai-landing-interested-btn">
                    முயற்சிசெய்துபாருங்களேன்!
                  </span>
                </AiLandingInterestedButton>
              </div>
            </div>
          </div>
        </div>
        <div className="landing-section-3">
          <div className="container">
            {/* <div className="landing-product-tabs-wrapper">
                            <Nav tabs className="landing-products-tab-row">
                                <NavItem className={" " + classnames({ active: activeTab == 1 })} onClick={() => { activeToggle(1) }}>
                                    <p>Authors</p>
                                </NavItem>
                                <NavItem className={" " + classnames({ active: activeTab == 2 })} onClick={() => { activeToggle(2) }}>
                                    <p>Translators</p>
                                </NavItem>
                                <NavItem className={" " + classnames({ active: activeTab == 3 })} onClick={() => { activeToggle(3) }}>
                                    <p>Publishers</p>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane className="active-anim" tabId={1}>
                                    <div className="ai-landing-features-row">
                                        <div className="ai-landing-features-content">
                                            <h1 className="title">Authors</h1>
                                            <ul className="features-list">
                                                <li>You can either translate your books yourself or hire professional translators.</li>
                                                <li>You can become a bilingual author if you are fluent in two languages.</li>
                                                <li>Your book&apos;s formatting will be preserved thanks to the Auto-DTP* technology.</li>
                                                <li>All of your translation projects can be managed in one place.</li>
                                                <li>Multilingual Self-publishing brings you wider reach.</li>
                                            </ul>
                                        </div>
                                        <div className="ai-landing-features-media">
                                            <div className="ai-landing-features-img">
                                                <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/landing-page/landing-page-img-2.png"} alr="landing-page-img-1"/>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane className="active-anim" tabId={2}>
                                    <div className="ai-landing-features-row-new">
                                        <div className="ai-landing-features-media">
                                            <div className="ai-landing-features-img">
                                                <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/landing-page/landing-page-img-3.png"} alr="landing-page-img-1"/>
                                            </div>
                                        </div>
                                        <div className="ai-landing-features-content">
                                            <h1 className="title">Translators</h1>
                                            <ul className="features-list">
                                                <li>Are you worried that machine translation will take your job? No. Make it your companion.</li>
                                                <li>Editing books and other contents bring you more money.</li>
                                                <li>Ailays&apos;s easy-to-use translation editor makes your life easier.</li>
                                                <li>Join Ailaysa&apos;s freelancer marketplace to earn more.</li>
                                                <li>Develop your own glossaries and translation memories.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane className="active-anim" tabId={3}>
                                    <div className="ai-landing-features-row">
                                        <div className="ai-landing-features-content">
                                            <h1 className="title">Publishers</h1>
                                            <ul className="features-list">
                                                <li>By translating your titles into various languages, you can reuse your content assets.</li>
                                                <li>Ailaysa can save you up to 1/3 of the expense of a manual translation.</li>
                                                <li>Translate books in weeks, not months and years.</li>
                                                <li>Suitable for non-fiction and academic books.</li>
                                                <li>Use your own translators or hire from Ailaysa&apos;s freelancer marketplace.</li>
                                            </ul>
                                        </div>
                                        <div className="ai-landing-features-media">
                                            <div className="ai-landing-features-img">
                                                <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/landing-page/landing-page-img-1.png"} alr="landing-page-img-1"/>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div> */}
            <div className="landing-product-tabs-wrapper">
              <div className="landing-features-tab-row-wrapper">
                <Nav className="landing-products-tab-row">
                  <NavItem
                    className={addActiveClassAuthor ? "active" : null}
                    onClick={() => handleScrollToAuthor("click")}
                  >
                    <p>Authors</p>
                  </NavItem>
                  <NavItem
                    className={addActiveClassTranslator ? "active" : null}
                    onClick={() => handleScrollToTranslators("click")}
                  >
                    <p>Translators</p>
                  </NavItem>
                  <NavItem
                    className={addActiveClassPublisher ? "active" : null}
                    onClick={() => handleScrollToPublishers("click")}
                  >
                    <p>Publishers</p>
                  </NavItem>
                </Nav>
              </div>
              <div ref={authorSection} className="ai-landing-features-row">
                <div className="ai-landing-features-content">
                  <h1 className="title">Authors</h1>
                  <ul className="features-list">
                    <li>
                      You can either translate your books yourself or hire
                      professional translators.
                    </li>
                    <li>
                      You can become a bilingual author if you are fluent in two
                      languages.
                    </li>
                    <li>
                      Your book&apos;s formatting will be preserved thanks to
                      the Auto-DTP* technology.
                    </li>
                    <li>
                      All of your translation projects can be managed in one
                      place.
                    </li>
                    <li>
                      Multilingual Self-publishing brings you wider reach.
                    </li>
                  </ul>
                </div>
                <div className="ai-landing-features-media">
                  <div className="ai-landing-features-img">
                    <img
                      src={
                        Config.aiDomainConfigs.CDN_DOMAIN +
                        "/assets/images/landing-page/landing-page-img-2.png"
                      }
                      alt="landing-page-img-1"
                    />
                  </div>
                </div>
              </div>
              <div
                ref={translatorSection}
                className="ai-landing-features-row-new"
              >
                <div className="ai-landing-features-media">
                  <div className="ai-landing-features-img">
                    <img
                      src={
                        Config.aiDomainConfigs.CDN_DOMAIN +
                        "/assets/images/landing-page/landing-page-img-3.png"
                      }
                      alt="landing-page-img-1"
                    />
                  </div>
                </div>
                <div className="ai-landing-features-content">
                  <h1 className="title">Translators</h1>
                  <ul className="features-list">
                    <li>
                      Are you worried that machine translation will take your
                      job? No. Make it your companion.
                    </li>
                    <li>
                      Editing books and other contents bring you more money.
                    </li>
                    <li>
                      Ailaysa&apos;s easy-to-use translation editor makes your
                      life easier.
                    </li>
                    <li>
                      Join Ailaysa&apos;s freelancer marketplace to earn more.
                    </li>
                    <li>
                      Develop your own glossaries and translation memories.
                    </li>
                  </ul>
                </div>
              </div>
              <div ref={publisherSection} className="ai-landing-features-row">
                <div className="ai-landing-features-content">
                  <h1 className="title">Publishers</h1>
                  <ul className="features-list">
                    <li>
                      By translating your titles into various languages, you can
                      reuse your content assets.
                    </li>
                    <li>
                      Ailaysa can save you up to 1/3 of the expense of a manual
                      translation.
                    </li>
                    <li>Translate books in weeks, not months and years.</li>
                    <li>Suitable for non-fiction and academic books.</li>
                    <li>
                      Use your own translators or hire from Ailaysa&apos;s
                      freelancer marketplace.
                    </li>
                  </ul>
                </div>
                <div className="ai-landing-features-media">
                  <div className="ai-landing-features-img">
                    <img
                      src={
                        Config.aiDomainConfigs.CDN_DOMAIN +
                        "/assets/images/landing-page/landing-page-img-1.png"
                      }
                      alt="landing-page-img-1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mob-key-features-wrapper">
              <div className="ai-landing-features-row">
                <div className="ai-landing-features-media">
                  <div className="ai-landing-features-img">
                    <img
                      src={
                        Config.aiDomainConfigs.CDN_DOMAIN +
                        "/assets/images/landing-page/landing-page-img-2.png"
                      }
                      alt="landing-page-img-1"
                    />
                  </div>
                </div>
                <div className="ai-landing-features-content">
                  <h1 className="title">Authors</h1>
                  <ul className="features-list">
                    <li>
                      You can either translate your books yourself or hire
                      professional translators.
                    </li>
                    <li>
                      You can become a bilingual author if you are fluent in two
                      languages.
                    </li>
                    <li>
                      Your book&apos;s formatting will be preserved thanks to
                      the Auto-DTP* technology.
                    </li>
                    <li>
                      All of your translation projects can be managed in one
                      place.
                    </li>
                    <li>
                      Multilingual Self-publishing brings you wider reach.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="ai-landing-features-row">
                <div className="ai-landing-features-media">
                  <div className="ai-landing-features-img">
                    <img
                      src={
                        Config.aiDomainConfigs.CDN_DOMAIN +
                        "/assets/images/landing-page/landing-page-img-3.png"
                      }
                      alt="landing-page-img-1"
                    />
                  </div>
                </div>
                <div className="ai-landing-features-content">
                  <h1 className="title">Translators</h1>
                  <ul className="features-list">
                    <li>
                      Are you worried that machine translation will take your
                      job? No. Make it your companion.
                    </li>
                    <li>
                      Editing books and other contents bring you more money.
                    </li>
                    <li>
                      Ailaysa&apos;s easy-to-use translation editor makes your
                      life easier.
                    </li>
                    <li>
                      Join Ailaysa&apos;s freelancer marketplace to earn more.
                    </li>
                    <li>
                      Develop your own glossaries and translation memories.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="ai-landing-features-row">
                <div className="ai-landing-features-media">
                  <div className="ai-landing-features-img">
                    <img
                      src={
                        Config.aiDomainConfigs.CDN_DOMAIN +
                        "/assets/images/landing-page/landing-page-img-1.png"
                      }
                      alt="landing-page-img-1"
                    />
                  </div>
                </div>
                <div className="ai-landing-features-content">
                  <h1 className="title">Publishers</h1>
                  <ul className="features-list">
                    <li>
                      By translating your titles into various languages, you can
                      reuse your content assets.
                    </li>
                    <li>
                      Ailaysa can save you up to 1/3 of the expense of a manual
                      translation.
                    </li>
                    <li>Translate books in weeks, not months and years.</li>
                    <li>Suitable for non-fiction and academic books.</li>
                    <li>
                      Use your own translators or hire from Ailaysa&apos;s
                      freelancer marketplace.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="landing-section-4">
          <div className="container">
            <h1 className="landing-key-feature-title">Key Features</h1>
            <div className="ai-landing-key-feature-row">
              <div className="ai-landing-key-feature-col">
                <h1>3X</h1>
                <small>faster</small>
              </div>
              <div className="ai-landing-key-feature-col">
                <h1>1/3</h1>
                <small>cost</small>
              </div>
              <div className="ai-landing-key-feature-col">
                <h1>100+</h1>
                <small>languages</small>
              </div>
              <div className="ai-landing-key-feature-col">
                <h1>1</h1>
                <small>
                  Translation management software that is all-in-one
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="landing-section-5">
          <div className="container">
            <div className="ai-landing-opport-wrapper">
              <h1>Now is the time to join the global publishing revolution.</h1>
              <p>
                Begin with a free account and take advantage of FREE training in
                English and Tamil on how to use AI for book translations.
              </p>
              <AiLandingSignupButton onClick={handleScrollUp}>
                <span className="ai-landing-btn-txt-1">
                  Sign Up <span>- It&apos;s FREE</span>
                </span>
              </AiLandingSignupButton>
            </div>
          </div>
        </div>
        <div className="landing-footer">
          <div className="container">
            <div className="landing-footer-row">
              <div className="landing-footer-col">
                <span>
                  <img
                    src={
                      Config.aiDomainConfigs.CDN_DOMAIN +
                      "/assets/images/landing-page/ailaysa-footer-logo.svg"
                    }
                    alt="footer-logo"
                  />
                </span>
                <span className="desc">
                  Ailaysa is a platform for machine translation post-editing
                  (MTPE) technology and service.
                </span>
              </div>
              <div className="landing-footer-col">
                <p className="txt">
                  © Langscape Language Solutions Private Limited 2022
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* </motion.section>
        </AnimatePresence>
      </SmoothScroll> */}
    </React.Fragment>
  );
}

export default LandingPage;
