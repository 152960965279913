import React, { useState, useEffect, useContext } from "react";
import { aiApiConfigs } from "../config/Config";
import { UserContext } from "../App";
import { Card } from "react-bootstrap";

const UserAvatar = ({ sizeProp }) => {
  const API_URL = aiApiConfigs.API_URL;
  const user = useContext(UserContext);

  return (
    <div className="avatar-profile-img-navbar overlay-image">
      {user?.profileImages?.avatar ? (
        <Card.Img variant="top" src={API_URL + user.profileImages.avatar} />
      ) : (
        <div>{user?.userInfo?.fullname?.charAt(0)?.toUpperCase()}</div>
      )}
    </div>
  );
};

export default UserAvatar;
