import React, { useState, useEffect, useRef, useLayoutEffect, useContext, lazy, Suspense } from "react";
import { Link, useHistory } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Typewriter from "minimalist-typewriter";
import { TabContent, TabPane, Nav, NavItem, NavLink, Fade } from "reactstrap";
import classnames from "classnames";
import Select, { components } from "react-select";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TestimonialSlider from "./testimonial-slider/Slider";
import CommonHeader from "../layouts/CommonHeader";
// import Tooltip from '@material-ui/core/Tooltip'
// import Zoom from '@material-ui/core/Zoom'
import DragandDrop from "./DragandDrop";
// import Sourcelanguage from "./home-lang-modal/Sourcelanguage";
// import Targetlanguage from "./home-lang-modal/Targetlanguage";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import Header from "../layouts/Header";
// import Footer from "../layouts/Footer";
import SmoothScroll from "../smoothscroll/SmoothScroll";
import { useTranslation } from "react-i18next";
import { UserContext } from "../App";
import {
    getProjectsFromTransEditor,
    getProjectFilesAndJobsFromTransEditor,
    getFilesDocumentURLFromTransEditor,
    getTransEditorSupportedFileFormats,
    hasAccess,
    setRequestSource,
    getFilesDocumentIdFromTranseditor,
} from "../helper/ProtectedCallHelper";
// import Typed from "typed.js";
import { pagetransition, transitionDuration } from "../routes/AllRoutes";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Config, { aiApiConfigs, aiDomainConfigs } from "../config/Config";
import Cookies from "js-cookie";
// import ActivateAccount from "../modals/ActivateModal";
// import AccountActivated from "../modals/ActivatedModal";
import Helmet from "react-helmet";
import HomeAccordianOne from "../animation/HomeAccordian-1"
import HomeAccordianTwo from "../animation/HomeAccordian-2"
import HomeAccordianThree from "../animation/HomeAccordian-3"
import HomeAccordianFour from "../animation/HomeAccordian-4"
import HomeHeroImg from "../animation/HomeHeroImg";
import { LazyLoadImage } from "react-lazy-load-image-component";

// import { WhatsNew } from './home-page-components/WhatsNew';
// import { Features } from "./home-page-components/Features";
// import { WorldMap } from "./home-page-components/WorldMap";
// import { ImageWithContent } from "./home-page-components/ImageWithContent";


const Sourcelanguage = lazy(() => import('./home-lang-modal/Sourcelanguage'))
const Targetlanguage = lazy(() => import('./home-lang-modal/Targetlanguage'))
const ActivateAccount = lazy(() => import('../modals/ActivateModal'))
const AccountActivated = lazy(() => import('../modals/ActivatedModal'))
const Footer = lazy(() => import('../layouts/Footer'))
const WhatsNew = lazy(() => import('./home-page-components/WhatsNew'))
const Features = lazy(() => import('./home-page-components/Features'))
const WorldMap = lazy(() => import('./home-page-components/WorldMap'))
const ImageWithContent = lazy(() => import('./home-page-components/ImageWithContent'))



toast.configure();

function Home(props) {
    const API_URL = aiApiConfigs.API_URL;
    const { t } = useTranslation();
    const user = useContext(UserContext);
    const history = useHistory();
    let isActivated = Cookies.get("activating_user");

    let accessToken = user?.permission_tokens?.token;
    let refreshToken = user?.permission_tokens?.refresh_token;
    const user_status = !user?.userInfo?.deactivate;

    const [homeActiveTab, setHomeActiveTab] = useState("1");
    const [accordianImgSwap, setAccordianImgSwap] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [files, setFiles] = useState([]);
    const [fileSize, setFileSize] = useState(0);
    const [showFileUpload, setShowFileUpload] = useState(false);
    const [projectName, setProjectName] = useState("");
    const [showSrcLangModal, setshowSrcLangModal] = useState(false);
    const [showTarLangModal, setshowTarLangModal] = useState(false);
    const [showSupportedFilelist, setshowSupportedFileList] = useState(false);
    const [srcLand, setsrcLand] = useState("");
    const [srcLandId, setsrcLandId] = useState("");
    const [tarLand, settarLand] = useState([]);
    const [tarLandLength, settarLandLength] = useState([]);
    const [textareaValue, setTextareaValue] = useState("");
    const [textareaValueLength, setTextareaValueLength] = useState(0);
    const [wordCount, setWordCount] = useState("");
    const [limitWords, setLimitWords] = useState(null);
    const [result, setResult] = useState(5000);
    const [maximumLimitOfWords, setMaximumLimitOfWords] = useState(5000);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [supportedFileFormats, setSupportedFileFormats] = useState([]);
    const [getStarted, setGetStarted] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const [title, setTitle] = useState("");
    const [activateModalShow, setActivateModalShow] = useState(false);
    const [activatedModalShow, setActivatedModalShow] = useState(false);

    const [inputDataError, setInputDataError] = useState("");
    const [fileDataError, setFileDataError] = useState("");
    const [deactivatedStatusError, setDeactivatedStatusError] = useState("");
    const [fileFormatError, setFileFormatError] = useState("");
    const [maxFileError, setMaxFileError] = useState("");
    const [sourceLanguageError, setSourceLanguageError] = useState("");
    const [targetLanguageError, setTargetLanguageError] = useState("");

    const outsideClickClose = useRef();
    const typingLanguages = useRef(null);
    const allowedSingleFileSize = useRef(100); // in MB
    const singleFileSizeError = useRef(t("file_size_exceeds_max_limit"));

    useEffect(() => {
        if (isActivated) {
            setTitle(t("activated"));
            setTimeout(() => setActivatedModalShow(true), 500);
            Cookies.remove("activating_user");
        }
    }, []);

    useEffect(() => {
        hasAccess(
            Cookies.get(aiDomainConfigs.USER_STATE) && JSON.parse(Cookies.get(aiDomainConfigs.USER_STATE))?.token,
            Cookies.get(aiDomainConfigs.USER_STATE) && JSON.parse(Cookies.get(aiDomainConfigs.USER_STATE))?.refresh_token
        ).then((res) => (res !== undefined ? setIsAuthenticated(true) : setIsAuthenticated(false)));
    }, []);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (showSupportedFilelist && outsideClickClose.current && outsideClickClose.current.contains(e.target)) {
                setshowSupportedFileList(false);
            }
        };
        // setWindowWidth(window.innerWidth)
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [showSupportedFilelist, window.innerWidth]);

    useEffect(() => {
        if (files.length >= 0) {
            setInputDataError("");
            setFileDataError("");
        }
    }, [files]);

    useEffect(() => {
        if (srcLandId) {
            setSourceLanguageError("");
        }
    }, [srcLandId]);

    useEffect(() => {
        if (tarLand.length > 0) {
            setTargetLanguageError("");
        }
    }, [tarLand]);

    // useEffect(() => {
    //     const typed = new Typed(typingLanguages.current, {
    //         strings: languages,
    //         typeSpeed: 150,
    //         backSpeed: 120,
    //         backDelay: 500,
    //         smartBackspace: true,
    //         loop: true,
    //         showCursor: false,
    //     });

    //     // Destropying
    //     return () => {
    //         typed.destroy();
    //     };
    // }, []);

    const handleTabToggle = (tab) => {
        if (homeActiveTab !== tab) setHomeActiveTab(tab);
    };

    const showSrcValueModal = (status, name, value) => {
        setshowSrcLangModal(status);
        setsrcLand(name);
        setsrcLandId(value);
    };

    const showTarValueModal = (status, value) => {
        setshowTarLangModal(status);
        settarLand(value);
        settarLandLength(value?.length);
    };

    const HandleTextareChange = (e) => {
        // if (user_status && e.target.value?.length > 0) {
        //   setDeactivatedStatusError(
        //     "Your account is not active. You need to activate your account to get started."
        //   );
        // }
        if (e.target.value?.length <= 5000) {
            setTextareaValue(e.target.value);
            setTextareaValueLength(e.target.value?.length);
        }
        setWordCount(e.target.value.length);
        setLimitWords(5000 - wordCount < 0 ? textareaValueLength : null);
        setResult(5000 - e.target.value.length);
        if (!(textareaValue === "")) {
            setInputDataError("");
            setFileDataError("");
        }
    };

    const resetTextareaField = () => {
        setTextareaValue("");
        setTextareaValueLength(0);
        setResult(5000);
    };

    let tooManyChars;
    if (result < 0) {
        tooManyChars = <p className="ai-max-words-validation-alert">{t("max_char_limit_exceeded")}</p>;
    }

    const showSrcModal = () => {
        document.body.classList.add("overflow-hidden");
        setshowSrcLangModal(true);
    };

    const hideSrcModal = () => {
        document.body.classList.remove("overflow-hidden");
        setshowSrcLangModal(false);
    };

    const showTarModal = () => {
        document.body.classList.add("overflow-hidden");
        setshowTarLangModal(true);
    };
    const hideTarModal = () => {
        document.body.classList.remove("overflow-hidden");
        setshowTarLangModal(false);
    };

    const handleshowSupportedFilelist = () => {
        setshowSupportedFileList(true);
    };

    const supported_file_list = [
        ".txt",
        ".html",
        ".tsv",
        ".csv",
        ".odp",
        ".ods",
        ".odt",
        ".json",
        ".properties",
        ".po",
        ".docx",
        ".xlsx",
        ".pptx",
        ".dotx",
        ".xliff",
        ".xlf",
        ".idml",
        ".srt",
        ".strings",
    ];

    const modaloption = {
        closeMaskOnClick: false,
        onClose: hideSrcModal,
    };

    const homeAccordian = [
        {
            title: t("home_accordian_title_1"),
            contentone: t("home_accordian_t1_content_1"),
            contenttwo: t("home_accordian_t1_content_2"),
            animateImg: <HomeAccordianOne />,
        },
        {
            title: t("home_accordian_title_2"),
            contentone: t("home_accordian_t2_content_1"),
            contenttwo: t("home_accordian_t2_content_2"),
            animateImg: <HomeAccordianThree />,
        },
        {
            title: t("home_accordian_title_3"),
            contentone: t("home_accordian_t3_content_1"),
            contenttwo: t("home_accordian_t3_content_2"),
            animateImg: <HomeAccordianTwo />,
        },
        {
            title: t("home_accordian_title_4"),
            contentone: t("home_accordian_t4_content_1"),
            contenttwo: t("home_accordian_t4_content_2"),
            animateImg: <HomeAccordianFour />,
        },
    ];




    

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const AiTextBoxButton = withStyles((theme) => ({
        root: {
            backgroundColor: "#0078D4",
            boxShadow: "none",
            borderRadius: "2px",
            textTransform: "none",
            padding: 0,
            "&:hover": {
                backgroundColor: "#0265b1",
                boxShadow: "none",
            },
        },
    }))(Button);

    const AiCallToActionButton = withStyles((theme) => ({
        root: {
            backgroundColor: "#0078D4",
            boxShadow: "none",
            borderRadius: "2px",
            textTransform: "none",
            padding: 0,
            "&:hover": {
                backgroundColor: "#0265b1",
                boxShadow: "none",
            },
        },
    }))(Button);

    const AiCallToActionButtonNew = withStyles((theme) => ({
        root: {
            backgroundColor: "#E4F3FF",
            boxShadow: "none",
            borderRadius: "2px",
            textTransform: "none",
            padding: 0,
            "&:hover": {
                backgroundColor: "#ffffff",
                boxShadow: "none",
            },
        },
    }))(Button);

    const languages = ["Hi", "வணக்கம்", "こんにちは", "Hola", "Bonjour", "你好", "नमस्ते", "Привет", "Merhaba"];

    

    useEffect(async () => {
        let supported_file_formats_list = [];
        let supported_file_formats_list_sorted = [];

        try {
            supported_file_formats_list = await getTransEditorSupportedFileFormats();

            if (supported_file_formats_list?.length > 0) {
                supported_file_formats_list
                    .map((sff, idx) => sff.split(".").join(""))
                    .sort(function (a, b) {
                        if (a.toLowerCase() < b.toLowerCase()) return -1;
                        if (a.toLowerCase() > b.toLowerCase()) return 1;
                        return 0;
                    })
                    .map((item, i) => supported_file_formats_list_sorted.push("." + item));

                setSupportedFileFormats(supported_file_formats_list_sorted);
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    const handleDrop = (filesTemp) => {
        let fileList = [...files];
        Object.keys(filesTemp).map((eachKey) => {
            if (isSupportedFile(filesTemp[eachKey])) {
                if (maxSizeCheck(filesTemp[eachKey])) {
                    fileList.push(filesTemp[eachKey]);
                    setMaxFileError("");
                } else setMaxFileError(singleFileSizeError.current);
            } else console.log("unsupported");
        });
        setFiles(fileList);
        setShowFileUpload(false);
    };

    const maxSizeCheck = (file) => {
        if (file?.size / 1024 / 1024 <= allowedSingleFileSize.current) return true;
        else return false;
    };

    const isSupportedFile = (file) => {
        let name = file.name;
        let lastDot = name.lastIndexOf(".");
        let fileName = name.substring(0, lastDot);
        let ext = "." + name.substring(lastDot + 1);

        return supportedFileFormats.includes(ext);
    };


    const handleFreeProjectCreation = () => {
        if(isAuthenticated){
            window.open(aiDomainConfigs.TRANSEDITOR_DOMAIN + "/create/translate/text/instant-text", "_self")
        }else{
            history.push("/try-instant-text")
        }
    }

    const handleSpeechTryThis = () => {
        if(isAuthenticated){
            window.open(aiDomainConfigs.TRANSEDITOR_DOMAIN + "/create/speech/speech-to-text", "_self")
        }else{
            Cookies.set("aiFeaturedValue", "speech", {
                domain: aiDomainConfigs.COOKIE_DOMAIN,
            });
            window.open(aiDomainConfigs.AUTH_DOMAIN, "_self")
        }
    }

    const handleGlossaryTryThis = () => {
        if(isAuthenticated){
            window.open(aiDomainConfigs.TRANSEDITOR_DOMAIN + "/create/assets/glossaries/create", "_self")
        }else{
            Cookies.set("aiFeaturedValue", "glossary", {
                domain: aiDomainConfigs.COOKIE_DOMAIN,
            });
            window.open(aiDomainConfigs.AUTH_DOMAIN, "_self")
        }
    }

    const removeFile = (e, index) => {
        let filesTemp = files;
        delete filesTemp[index];
        let isFilesEmpty = true;
        let finalFiles = [];
        Object.keys(filesTemp).map((eachKey) => {
            if (filesTemp[eachKey] != null) {
                isFilesEmpty = false;
                finalFiles.push(filesTemp[eachKey]);
            }
        });
        if (isFilesEmpty) filesTemp = [];
        setFiles(finalFiles);
    };

    const handleChange = (e) => {
        switch (e.target.name) {
            case "files": {
                let thisFiles = e.target.files;
                let fileList = [...files];
                Object.keys(thisFiles).map((eachKey) => {
                    if (isSupportedFile(thisFiles[eachKey])) {
                        if (maxSizeCheck(thisFiles[eachKey])) {
                            fileList.push(thisFiles[eachKey]);
                            setMaxFileError("");
                        } else setMaxFileError(singleFileSizeError.current);
                        setFileFormatError("");
                    } else setFileFormatError(t("file_format_unsupported"));
                });
                setFiles(fileList);
                break;
            }
            case "projectName": {
                setProjectName(e.target.value);
                break;
            }
            default: {
                break;
            }
        }
    };

    // const pagetransition = {
    //     in: {
    //         opacity: 1,
    //     },
    //     out: {
    //         opacity: 0.05,
    //     },
    // };

    const getFileSize = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const checkWhetherTaskIsSingle = (data) => {
        if (data?.files?.length === 1 && data?.jobs?.length === 1) return true;
        else false;
    };

    const getFilesAndJobsLength = async (token, project_id) => {
        let files_and_jobs = await getProjectFilesAndJobsFromTransEditor(token, project_id);

        if (checkWhetherTaskIsSingle(files_and_jobs)) {
            let documentInformation = await getFilesDocumentURLFromTransEditor(token, project_id);
            let documentIdInformation = await getFilesDocumentIdFromTranseditor(token, documentInformation[0]?.document_url);
            return documentIdInformation?.document_id;
        } else return;
    };

    // useEffect(async () => {
    //   console.log(await getFilesAndJobsLength(accessToken, 473));
    // }, []);

    const getRecentlyCreatedProjectId = async (token) => {
        let new_project_id = "";
        const project_data = await getProjectsFromTransEditor(token);
        project_data?.results?.slice(0, 1)?.map((project) => (new_project_id = project?.id));
        return new_project_id;
    };

    const showAccountActivationRequiredModal = () => {
        setTimeout(() => {
            setTitle(t("activate_account"));
            setActivateModalShow(true);
        }, 200);
    };


    const handleGetStarted = async (e) => {
        e.preventDefault();
        // if (user_status) {
        //   showAccountActivationRequiredModal();
        //   return;
        // }
        if (textareaValue === "" && files.length === 0) {
            setInputDataError(t("file_or_text_required"));
            return;
        } else if (textareaValueLength > 0 && files.length > 0) {
            setFileDataError(t("cannot_add_text_and_files"));
            return;
        } else if (!srcLandId) {
            setSourceLanguageError(t("sl_required"));
            return;
        } else if (!tarLand || tarLand.length <= 0) {
            setTargetLanguageError(t("tl_required"));
            return;
        }

        let myHeaders = new Headers();
        let formdata = new FormData();
        let selected_target_languages = [];

        setGetStarted(true);
        try {
            const getAccessToken = await hasAccess(accessToken, refreshToken);
            if (getAccessToken === undefined) {
                tarLand.map((tl) => {
                    selected_target_languages.push(tl);
                });

                formdata.append("source_language", JSON.stringify(srcLandId));
                formdata.append("target_languages", JSON.stringify(selected_target_languages));

                textareaValue ? formdata.append("text_data", textareaValue) : null;

                for (let x = 0; x < files.length; x++) {
                    if (typeof files[x] != "undefined") formdata.append("files", files[x]);
                }

                let requestOptions = {
                    method: "POST",
                    body: formdata,
                    redirect: "follow",
                };

                const temporary_project_post_response = await fetch(API_URL + "/workspace/temp_project_setup/", requestOptions);
                const data = await temporary_project_post_response.json();
                const status = await temporary_project_post_response.status; 

                if (status === 201) {
                    setRequestSource();
                    Cookies.set("new_project_id", data?.temp_proj_id, {
                        domain: aiDomainConfigs.COOKIE_DOMAIN,
                    });
                    window.location.href = aiDomainConfigs.AUTH_DOMAIN;
                    // history.push("/login");
                } else if (status === 406) {
                    toast.error(t("urls_not_accepted"), {
                        position: toast.POSITION.TOP_CENTER,
                    });
                } else {
                    toast.error(t("something_went_wrong"), {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            } else {
                myHeaders.append("Authorization", `Bearer ${accessToken}`);

                formdata.append("source_language", srcLandId);
                tarLand.map((tl) => {
                    formdata.append("target_languages", JSON.stringify(tl));
                });

                textareaValue ? formdata.append("text_data", textareaValue) : null;

                // files.map((file) => {
                //   formdata.append("files", files[0], file.path);
                // });

                for (let x = 0; x < files.length; x++) {
                    if (typeof files[x] != "undefined") formdata.append("files", files[x]);
                }

                let requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: formdata,
                    redirect: "follow",
                };

                const project_response = await fetch(API_URL + "/workspace/project/quick/setup/", requestOptions);

                const data = await project_response.json();
                const status = await project_response.status;

                if (status === 201) {
                    setRequestSource();
                    const project_id = await getRecentlyCreatedProjectId(accessToken);
                    let document_id = await getFilesAndJobsLength(accessToken, project_id);

                    if (document_id) {
                        window.location.href = aiDomainConfigs.TRANSEDITOR_DOMAIN + `/workspace/${document_id}`;
                    } else if (project_id) {
                        window.location.href = window.location.href = aiDomainConfigs.TRANSEDITOR_DOMAIN + `/file-upload?open-project=${project_id}`;
                    } else {
                        window.location.href = window.location.href = aiDomainConfigs.TRANSEDITOR_DOMAIN + `/file-upload`;
                    }
                } else if (status === 406) {
                    setInputDataError(t("urls_not_accepted"));
                } else {
                    toast.error(t("something_went_wrong"), {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }
            setTimeout(() => {
                setGetStarted(false);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{t("ailaysa_home")}</title>
                <meta name="description" content={t("ailaysa_home_desc")} />
            </Helmet>
            <Header />
            <section className="home-bg-banner">
                <CommonHeader />
                {/* <div className="container"> */}
                    <div className="ai-hero-banner-wrapper">
                        <div className="ai-hero-banner-row">
                            <div className="ai-hero-banner-col">
                                <div className="hero-text-area">
                                    <h1 className="main-title">Create <span>Multilingual Content</span></h1>
                                    <h2 className="sub-main-txt">AI powered translation platform that takes your business to every country on the planet.</h2>
                                    {/* <h5 className="sub-para">Instantly translate your content in any format (text, files, audio and video) into 100+ languages. You can improve and customize translation on your own, or hire a professional editor from our marketplace.</h5> */}
                                    <AiTextBoxButton onClick={handleFreeProjectCreation}>
                                        <span className="ai-textbox-btn">Start a project now, <span>free!</span></span>
                                    </AiTextBoxButton>
                                </div>
                            </div>
                            <div className="ai-hero-banner-col">
                                {/* <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/new-home-hero-img-v3.webp"} alt="hero-banner-img"/> */}
                                <HomeHeroImg />
                            </div>
                        </div>
                    </div>
                {/* </div> */}
                {/* <div className="container">
                    <h2 className="ai-home-main-title">
                        <span>
                            {t("say")}
                            Need to Hide
                            <Typewriter
                    strings={languages}
                    default="Hi"
                    options={typeoptions}
                />{" "}
                Need to Hide
                            <span className="typing-text" ref={typingLanguages}></span>
                        </span>
                        {t("to_the_world")}
                    </h2>
                    <h1 className="ai-home-main-title-1">
                        {t("ai_translation_and")} <span>{t("human_editing")}</span>
                    </h1>
                    <p className="ai-home-banner-txt">
                        {t("header_1")} <span>{t("header_2")}</span>
                    </p>
                    <div className="ai-sample-text-add-part">
                        <div className="ai-sample-textbox-dots-img">
                            <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/home-dots.svg"} />
                        </div>
                        <div className="ai-translate-text-add-card">
                            <Nav tabs>
                                <div className="ai-text-tabs-algin">
                                    <NavItem
                                        className={
                                            "ai-tab-txt " +
                                            classnames({
                                                home_tab_border_active: homeActiveTab === "1",
                                            })
                                        }
                                        onClick={() => {
                                            handleTabToggle("1");
                                        }}
                                    >
                                        <NavLink
                                            className={classnames({
                                                home_tab_active: homeActiveTab === "1",
                                            })}
                                            onClick={() => {
                                                handleTabToggle("1");
                                            }}
                                        >
                                            {t("add_text")}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem
                                        className={
                                            "ai-tab-txt " +
                                            classnames({
                                                home_tab_border_active: homeActiveTab === "2",
                                            })
                                        }
                                        onClick={() => {
                                            handleTabToggle("2");
                                        }}
                                    >
                                        <NavLink
                                            className={classnames({
                                                home_tab_active: homeActiveTab === "2",
                                            })}
                                            onClick={() => {
                                                handleTabToggle("2");
                                            }}
                                        >
                                            {t("add_files")}
                                        </NavLink>
                                    </NavItem>
                                </div>
                                <ul className={homeActiveTab === "2" ? "d-none" : "ai-textbox-features"}>
                                    Need to hide
                                    <li><span><img src={Config.aiDomainConfigs.CDN_DOMAIN+"/assets/images/home/mic_black.svg"} alt="mic" /></span></li>
                                    Need to hide
                                    <li>
                                        <span onClick={resetTextareaField}>
                                            <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/delete_black.svg"} alt="delete" />
                                        </span>
                                    </li>
                                </ul>
                            </Nav>
                            <TabContent activeTab={homeActiveTab}>
                                <TabPane tabId="1">
                                    <div className="text-area-cont">
                                        <textarea
                                            className="ai-home-textarea"
                                            id="ai-home-textarea"
                                            value={textareaValue}
                                            onChange={HandleTextareChange}
                                            maxLength={limitWords}
                                            placeholder={t("type_your_text_here")}
                                        ></textarea>
                                        {tooManyChars}
                                        {inputDataError ? (
                                            <div className="ai-texbox-validation-alert">
                                                <p>
                                                    <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/icons/error_icon.svg"} /> {inputDataError}
                                                </p>
                                            </div>
                                        ) : null}
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <div className={!showFileUpload && files.length > 0 ? "d-none" : ""}>
                                        <DragandDrop handleDrop={handleDrop}>
                                            <div className="ai-drag-n-drop-container">
                                                <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/drag-n-drop-folder.svg"} alt="drag-n-drop-folder" />
                                                <div className="file-upload-align">
                                                    <p className="upload-text">{t("add_files_desc_1") + " "}</p>
                                                    <div className="upload-link-wrapper">
                                                        <label htmlFor="files">{t("add_files_desc_2")}</label>
                                                        <input type="file" name="files" className="form-control-file" id="files" onChange={handleChange} multiple hidden />
                                                    </div>
                                                </div>
                                                <div className="file-upload-instruction">
                                                    <span className="max-word-note">
                                                        {t("recommended_max_words")}
                                                        <span>{t("50000")}</span> ; {t("max_words_instruction")}
                                                    </span>
                                                    Need to Hide
                                                    <span className="max-word-note file-upl-inst-padd-left">
                                Bigger files may lead to slow process
                            </span>
                            Need to Hide
                                                    <span className="max-file-note">
                                                        {t("max_file_size")} <span>{t("100")}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </DragandDrop>
                                    </div>
                                    <div className={!showFileUpload && files.length == 0 ? "d-none" : ""}>
                                        <DragandDrop handleDrop={handleDrop}>
                                            <div className="ai-drag-n-drop-container-file-list">
                                                <div className="file-list-align">
                                                    <div className="file-list">
                                                        {Object.keys(files).map((eachKey) => {
                                                            return (
                                                                <div key={eachKey + files[eachKey].name} className="file-name-list">
                                                                    <div className="filename">
                                                                        {
                                                                            <img
                                                                                src={
                                                                                    "https://pro.alchemdigital.com/api/extension-image/" + files[eachKey].name.split(".").pop()
                                                                                }
                                                                                alt="document"
                                                                            />
                                                                        }
                                                                        <div>
                                                                            <span className="filename-length">{files[eachKey].name.split(".").slice(0, -1).join(".")}</span>
                                                                            <span className="extension">{"." + files[eachKey].name.split(".").pop()}</span>
                                                                            <div className="file-size">{getFileSize(files[eachKey].size)}</div>
                                                                        </div>
                                                                    </div>
                                                                    <span className="upload-file-delete" data-file-index={eachKey} onClick={(e) => removeFile(e, eachKey)}>
                                                                        <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/icons/close_black.svg"} alt="delete" />
                                                                    </span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                    <div className="file-upload-align new-drag-n-drp-align">
                                                        <input type="file" name="files" id="files-drag" onChange={(e) => handleChange(e)} multiple hidden />
                                                        <label className="form-control-file" htmlFor="files-drag">
                                                            <span>
                                                                <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/link-pin.svg"} alt="link-pin" />
                                                            </span>{" "}
                                                            {t("add_more")}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </DragandDrop>
                                    </div>
                                    {inputDataError ? (
                                        <p className="ai-max-words-validation-new-alert">
                                            <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/icons/warning.svg"} /> {inputDataError}
                                        </p>
                                    ) : fileFormatError ? (
                                        <p className="ai-max-words-validation-new-alert">
                                            <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/icons/warning.svg"} /> {fileFormatError}
                                        </p>
                                    ) : maxFileError ? (
                                        <p className="ai-max-words-validation-new-alert">
                                            <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/icons/warning.svg"} /> {maxFileError}
                                        </p>
                                    ) : null}
                                </TabPane>
                            </TabContent>
                            <div className="ai-textbox-button-align">
                                <div className="ai-textbox-select">
                                    <div className="ai-drpdown-box">
                                        <div className={sourceLanguageError ? "ai-lang-select-error ai-lang-select" : "ai-lang-select"} onClick={showSrcModal}>
                                            <span>{srcLand == "" ? t("source_language") + " " : srcLand}</span> <i style={{ color: "#000000" }} className="fas fa-caret-down" />
                                        </div>
                                        {sourceLanguageError ? (
                                            <p className="mob-error ai-max-words-validation-new-alert">
                                                {" "}
                                                <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/icons/warning.svg"} /> {sourceLanguageError}
                                            </p>
                                        ) : null}
                                    </div>
                                    <div className="ai-drpdown-box">
                                        <div>
                                            <div className={targetLanguageError ? "ai-lang-select-error ai-lang-select" : "ai-lang-select"} onClick={showTarModal}>
                                                <span>
                                                    {tarLand == ""
                                                        ? t("target_language") + " "
                                                        : t("target_languages_selected", {
                                                                tarLandLength,
                                                            })}
                                                </span>{" "}
                                                <i style={{ color: "#000000" }} className="fas fa-caret-down" />
                                            </div>
                                            {targetLanguageError ? (
                                                <p className="mob-error ai-max-words-validation-new-alert">
                                                    {" "}
                                                    <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/icons/warning.svg"} />
                                                    {targetLanguageError}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className={homeActiveTab === "1" ? "ai-getstarted-btn" : "ai-new-padd-top ai-getstarted-btn"}>
                                    {homeActiveTab === "1" ? (
                                        <p className="ai-max-words-validation">
                                            {t("typing_chars", {
                                                textareaValueLength,
                                                maximumLimitOfWords,
                                            })}
                                        </p>
                                    ) : (
                                        <div className="ai-pl-3 d-flex align-items-center">
                                            <span className=" active-zoom-anim imp-note-icon">
                                                <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/icons/info.svg"} alt="info" />
                                            </span>
                                            <span>
                                                <p onClick={handleshowSupportedFilelist} className="supported-file-formats">
                                                    {t("supported_file_formats")}
                                                </p>
                                            </span>

                                            {showSupportedFilelist && (
                                                <>
                                                    <div className="supported-file-list-box">
                                                        <ul className="supported-file-list-cont">
                                                            {supportedFileFormats.map((name, index) => (
                                                                <li key={index}>{name}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )}
                                    <AiTextBoxButton onClick={handleGetStarted}>
                                        {getStarted ? (
                                            <span className="ai-textbox-btn">
                                                <div className="lds-spinner">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                                {t("loading")}
                                            </span>
                                        ) : (
                                            <span className="ai-textbox-btn">
                                                {t("get_started")}
                                            </span>
                                        )}
                                    </AiTextBoxButton>
                                </div>
                            </div>
                            {sourceLanguageError ? (
                                <p className="desktop-error ai-max-words-validation-new-alert">
                                    {" "}
                                    <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/icons/warning.svg"} /> {sourceLanguageError}
                                </p>
                            ) : null}
                            {targetLanguageError ? (
                                <p className="desktop-error ai-max-words-validation-new-alert">
                                    {" "}
                                    <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/icons/warning.svg"} />
                                    {targetLanguageError}
                                </p>
                            ) : null}
                            {fileDataError ? <p className="ai-max-words-validation-new-alert">{fileDataError}</p> : null}
                            {deactivatedStatusError ? <p className="ai-max-words-validation-new-alert">{deactivatedStatusError}</p> : null}
                        </div>
                    </div>
                    Need to Hide
                    <p className="ai-home-banner-icons-txt">Trusted by innovative companies worldwide</p>
                    <div className="ai-companies-logo">
                                    <div><img src={Config.aiDomainConfigs.CDN_DOMAIN+"/assets/images/home/facebook.svg"} alt="facebook"/></div>
                                    <div><img src={Config.aiDomainConfigs.CDN_DOMAIN+"/assets/images/home/facebook.svg"} alt="facebook"/></div>
                                    <div><img src={Config.aiDomainConfigs.CDN_DOMAIN+"/assets/images/home/facebook.svg"} alt="facebook"/></div>
                                    <div><img src={Config.aiDomainConfigs.CDN_DOMAIN+"/assets/images/home/facebook.svg"} alt="facebook"/></div>
                                    <div><img src={Config.aiDomainConfigs.CDN_DOMAIN+"/assets/images/home/facebook.svg"} alt="facebook"/></div>
                                </div>
                    Need to Hide
                    <div className="ai-home-features">
                        <div className="ai-home-features-content">
                            <h2>{t("3x")}</h2>
                            <p>{t("faster")}</p>
                        </div>
                        <div className="ai-home-features-content">
                            <h2>{t("3x")}</h2>
                            <p>{t("more_affordable")}</p>
                        </div>
                        <div className="ai-home-features-content">
                            <h2>{t("100_plus")}</h2>
                            <p>{t("languages")}</p>
                        </div>
                    </div>
                </div> */}
            </section>
            {/* <section className="languages-row-wrapper">
                <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/language-row-crop.png" } />
            </section> */}
            {/* <section className="ai-what-new-section">
                <div className="container">
                    <div className="text-center">
                        <h1 className="ai-what-new-title">What&apos;s New!</h1>
                    </div>
                    <div className="ai-what-new-row">
                        <div className="ai-what-new-col">
                            
                            <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/innovate-award-new.jpg"} />
                            
                            <LazyLoadImage 
                                src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/innovate-award-new.jpg"} 
                                effect="blur"
                                width={256}
                                height={180}
                            />
                            
                            <div className="content-wrap">
                                <h5 className="title">Innovation of the year 2022</h5>
                                <p className="excerpt">Ailaysa won a prestigious award from India&apos;s top language industry body, Citlob.</p>
                            </div>
                        </div>
                        <div className="ai-what-new-col">
                            <div className="img-vertical-align">
                                <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/ai-speech.png"} />
                                <LazyLoadImage 
                                    src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/ai-speech.png"} 
                                    effect="blur"
                                    width={150}
                                    height={122}
                                />
                            
                            </div>
                            <div className="content-wrap">
                                <h5 className="title">Ai Speech</h5>
                                <p className="excerpt">text &gt; speech, speech &gt; text,<br/> speech &gt; text &gt; translate &gt; speech.</p>
                                <div onClick={handleSpeechTryThis} className="link">Try now</div>
                            </div>
                        </div>
                        <div className="ai-what-new-col">
                            <div className="img-vertical-align">
                                <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/glossaries.png"} />
                                <LazyLoadImage 
                                    src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/glossaries.png"} 
                                    effect="blur"
                                    width={170}
                                    height={125}
                                />
                            </div>
                            <div className="content-wrap">
                                <h5 className="title">Terminology Management</h5>
                                <p className="excerpt">Create & manage glossaries, and add them to your localization projects.</p>
                                <div onClick={handleGlossaryTryThis} className="link">Try now</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <Suspense fallback={<div></div>}>
                <WhatsNew 
                    LazyLoadImage={LazyLoadImage}
                    Config={Config} 
                    handleSpeechTryThis={handleSpeechTryThis}
                    handleGlossaryTryThis={handleGlossaryTryThis }
                    homeAccordian={homeAccordian}
                />
            </Suspense>
            {/* <section className="ai-girl-robot-wrap">
                <div className="container">
                    <div className="text-center">
                        <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/girl-robot-img-new.png"} alt="girl-robot-img" />
                    </div>
                </div>
            </section> */}
            {/* <section className="ai-accordian-section">
                <div className="container">
                    <div className="text-center">
                        <h2 className="ai-home-accordian-title">{t("what_you_can_do_with_ailaysa")}</h2>
                    </div>
                    <div className="ai-accordian-bg-cont">
                        <div className="ai-accordian-content">
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                {homeAccordian.map((item, index) => {
                                    return (
                                        <div className="accordion-item" key={index}>
                                            <h2 className="accordion-header" id={"flush-heading_" + index}>
                                                <button
                                                    className={(accordianImgSwap == 0 && index == 0) || index === activeIndex ? "accordion-button" : "accordion-button collapsed"}
                                                    onClick={(e) => {setAccordianImgSwap(index); setActiveIndex(index)}}
                                                    type="button"
                                                    // data-bs-toggle="collapse"
                                                    data-bs-target={"#flush-collapse_" + index}
                                                    aria-expanded="false"
                                                    aria-controls={"flush-collapse_" + index}
                                                >
                                                    {item.title}
                                                </button>
                                            </h2>
                                            <div
                                                id={"flush-collapse_" + index}
                                                className={(accordianImgSwap == 0 && index == 0) || index === activeIndex ? "accordion-collapse collapse show" : "accordion-collapse collapse"}
                                                aria-labelledby={"flush-heading_" + index}
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>{item.contentone}</p>
                                                    <p>{item.contenttwo}</p>
                                                    <div className="mob-accordian-img">
                                                        <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/" + item.img} alt="accordian-img" />
                                                        {item.animateImg}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="ai-accrdian-img">
                            <div className={homeAccordian[accordianImgSwap] ? "img-swap-active-anim" : null}>
                            {homeAccordian.map((item, index) => {
                                return (
                                    <div key={index} className={"img-swap-anim " + (accordianImgSwap == index ? "img-swap-active-anim" : "")}>
                                        <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/" + homeAccordian[accordianImgSwap].img} alt="accordian-img" />
                                        {item.animateImg}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section> */}
            <Suspense fallback={<div></div>}>
                <Features 
                    t={t}
                    Config={Config} 
                    homeAccordian={homeAccordian}
                    accordianImgSwap={accordianImgSwap}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    setAccordianImgSwap={setAccordianImgSwap}
                />
            </Suspense>
            {/* <section className="ai-map-img-section">
                <div className="container mob-reverse-cont">
                    <div className="ai-svg-anime-bg">
                        <img className="svg-anime-bg" src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/map-img-world.png"} alt="map-svg" />
                        <LazyLoadImage 
                            className="svg-anime-bg" 
                            src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/map-img-world.png"} 
                            alt="map-svg" 
                            effect="blur"
                        />
                    </div>
                    <div className="bg-img-map-cont">
                        <h2>{t("one_world_one_platform")}</h2>
                        <p>{t("ailaysa_makes_global_journey")}</p>
                    </div>
                </div>
            </section> */}
            <Suspense fallback={<div></div>}>
                <WorldMap 
                    LazyLoadImage={LazyLoadImage}
                    Config={Config} 
                    t={t}
                />
            </Suspense>
            {/* <section className="ai-two-column-section">
                <div className="container">
                    <div className="ai-two-column-row">
                        <div className="ai-left-column">
                            <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/companies-with-global.png"} />
                            <LazyLoadImage 
                                src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/companies-with-global.png"} 
                                effect="blur"
                            />
                        </div>
                        <div className="ai-right-column">
                            <div className="ai-left-column-content">
                                <h2>{t("global_aspiration")}</h2>
                                <p>{t("global_aspiration_p1")}</p>
                                <p>{t("global_aspiration_p2")}</p>
                                <p className="ai-res-margin-bottom-add">{t("global_aspiration_p3")}</p>
                                <Link to="/pricing">{t("start_free")}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ai-two-column-section-2">
                <div className="container">
                    <div className="ai-two-column-row-2">
                        <div className="ai-left-column">
                            <div className="ai-left-column-content">
                                <h2>{t("digital_marketers")}</h2>
                                <p>{t("digital_marketers_p1")}</p>
                                <p>{t("digital_marketers_p2")}</p>
                                <p className="ai-res-margin-bottom-add">{t("digital_marketers_p3")}</p>
                                <Link to="/pricing">{t("start_free")}</Link>
                            </div>
                        </div>
                        <div className="ai-right-column">

                            <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/digital-marketers.png"} />
                            <LazyLoadImage 
                                src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/digital-marketers.png"} 
                                effect="blur"
                            />
                        
                        </div>
                    </div>
                </div>
            </section>
            <section className="ai-two-column-section">
                <div className="container">
                    <div className="ai-two-column-row">
                        <div className="ai-left-column">
                            <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/translators-content.png"} />
                            <LazyLoadImage 
                                src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/home/translators-content.png"} 
                                effect="blur"
                            />
                        </div>
                        <div className="ai-right-column">
                            <div className="ai-left-column-content">
                                <h2>{t("translation_and_content_writing")}</h2>
                                <p>{t("translation_and_content_writing_p1")}</p>
                                <p>{t("translation_and_content_writing_p2")}</p>
                                <p>{t("translation_and_content_writing_p3")}</p>
                                <p className="ai-res-margin-bottom-add">{t("translation_and_content_writing_p4")}</p>
                                <Link to="/pricing">{t("start_free")}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <Suspense fallback={<div></div>}>
                <ImageWithContent 
                    t={t}
                    Link={Link}
                    Config={Config}
                    LazyLoadImage={LazyLoadImage}
                    AiCallToActionButton={AiCallToActionButton}
                    AiCallToActionButtonNew={AiCallToActionButtonNew}
                />
            </Suspense>
            {/* <section className="ai-testimonial-cont">
                                <div className="container">
                                    <div className="testimonial-slider-part">
                                        <div className="ai-testi-circle"></div>
                                        <TestimonialSlider />
                                    </div>
                                </div>
                            </section> */}
            {/* <section className="ai-call-to-action-cont">
                <div className="container">
                    <div className="ai-call-to-action-box">
                        <h2>{t("get_started_header")}</h2>
                        <p>{t("get_started_description")}</p>
                        <div className="ai-common-call-to-action">
                            <Link to="/pricing">
                                <AiCallToActionButton
                                    // onClick={scrollToTop}
                                    className="ai-call-to-action-btn-txt-margin-right"
                                >
                                    <span className="ai-call-to-action-btn-txt">{t("get_started")}</span>
                                </AiCallToActionButton>
                            </Link>
                            <Link to="/contact">
                                <AiCallToActionButtonNew>
                                    <span className="ai-call-to-action-btn-txt-2">{t("contact_us")}</span>
                                </AiCallToActionButtonNew>
                            </Link>
                        </div>
                    </div>
                </div>
            </section> */}
            <Suspense fallback={<div></div>}>
                <Footer />
            </Suspense>
            {/* </motion.section> */}
            {/* </AnimatePresence> */}
            {/* </SmoothScroll> */}

            <Rodal visible={showSrcLangModal} {...modaloption} showCloseButton={false} className="ai-lang-select-modal">
                <div className="lang-modal-header">
                    <h2>{t("select_source_language")}</h2>
                    <span className="modal-close-btn" onClick={hideSrcModal}>
                        {/* <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/icons/close_black.svg"} alt="close_black" /> */}
                        <LazyLoadImage 
                            src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/icons/close_black.svg"} 
                            alt="close_black" 
                            effect="blur"
                        />
                    </span>
                </div>
                <Suspense fallback={<div></div>}>
                    <Sourcelanguage isOpen={showSrcValueModal} />
                </Suspense>
            </Rodal>
            <Rodal visible={showTarLangModal} {...modaloption} showCloseButton={false} className="ai-tar-lang-select-modal">
                <div className="lang-modal-header">
                    <h2>{t("select_target_languages")}</h2>
                    <span className="modal-close-btn" onClick={hideTarModal}>
                        {/* <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/icons/close_black.svg"} alt="close_black" /> */}
                        <LazyLoadImage 
                            src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/icons/close_black.svg"} 
                            alt="close_black" 
                            effect="blur"
                        />
                    </span>
                </div>
                <Suspense fallback={<div></div>}>
                    <Targetlanguage srcLang={srcLandId} isOpen={showTarValueModal} />
                </Suspense>
            </Rodal>
            <Suspense fallback={<div></div>}>
                <ActivateAccount title={t("activate_account")} show={activateModalShow} onHide={() => setActivateModalShow(false)} message={user_status} />
                <AccountActivated title={t("activated")} show={activatedModalShow} onHide={() => setActivatedModalShow(false)} />
            </Suspense>

        </React.Fragment>
    );
}

export default Home;
