import React from 'react';
import homeAccordianthree from './home-accordian-animation/homeAccord_3.json'
import Lottie from 'react-lottie'


const HomeAccordianThree = () => {

    const defaultOptions = {
        width: "90%",
        loop: true,
        autoplay: true,
        animationData: homeAccordianthree,
        rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
        },
    }

    return ( 
        <div className='home-accordian-img-wrapper'> 
            <Lottie options={defaultOptions} isClickToPauseDisabled={true} width={"85%"}/>
        </div>
    )
}

export default HomeAccordianThree